import React from 'react';
import NavTab from '../../components/navTab';
import { connect } from 'react-redux';
import { List, InputItem, TextareaItem, Switch, Picker } from 'antd-mobile';
import { Checkbox, Space, ImageUploader, Toast } from 'antd-mobile-v5';
import { createForm, formShape } from 'rc-form';
// import { loadState } from '../../store/localstorage'
import { isEmptyObject } from '../../utils/common';
import api, { apiUrl } from '../../utils/api';
import Fetch from '../../utils/fetch-config';
import { sleep } from 'antd-mobile-v5/es/utils/sleep'

import './release-posts.scss'

const Item = List.Item;

const mapStateToProps = store => ({
    userInfo: store.userInfo,
    departmentList: store.departmentList
});

class ReleasePosts extends React.Component {
    static propTypes = {
        form: formShape,
    };
    state={
        hasError: false,
        title: '',
        content: '',
        files: [],
        DepartmentList: [],
        checkDepartment: [],
        fileTypeList: [],
        checkFileType: [],
        visible: false,
        permissionList: [],
        checkPermission: [],
        saveTypeList: [],
        checkSaveType: [],
        filesCompressList: [],
        InfoContent: '',
        treeValue: []
    }
    componentWillMount() {
        const {departmentList} = this.props;
        let DepartmentList = [];
        if (!isEmptyObject(departmentList)){
            departmentList.map(item => {
                let obj = {};
                obj.label = item.DepartmentName;
                obj.value = item.DepartmentId;
                DepartmentList.push(obj)
            })
        }
        this.setState({
            DepartmentList
        })
    }
    componentWillReceiveProps () {}
    componentWillUnmount () { }
    componentDidShow () {}
    componentDidHide () { }

    async getFileTypeList(){
        const { checkDepartment } = this.state;
        let res = await Fetch.get(api.getFileTypeList+`?DepartmentId=${checkDepartment[0]}`);
        let fileTypeList = [];
        if (!isEmptyObject(res.data)){
            res.data.map(item => {
                let obj = {};
                obj.label = item.FileTypeName;
                obj.value = item.Id;
                fileTypeList.push(obj)
            })
        }
        this.setState({
            fileTypeList
        })
    }

    onErrorClick = () => {
        if (this.state.hasError) {
            Toast.show({
                content: '文档标题不能为空',
                maskClickable: false
            })
        }
    }
    
    onChangeTitle = (value) =>{
        if (value.replace(/\s/g, '').length <= 0) {
            this.setState({
                hasError: true,
            });
        } else {
            this.setState({
                hasError: false,
            });
        }
        this.setState({
            title: value,
        });
    }

    onChangeContent = (value) =>{
        this.setState({
            content: value,
        });
    }

    //将base64转换为blob
    dataURLtoBlob(dataurl) { 
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }
    //将blob转换为file
    blobToFile(theBlob, fileName){
       theBlob.lastModifiedDate = new Date();
       theBlob.name = fileName;
       return theBlob;
    }

    async userImageUpload(file, objName){
        // console.log(file,'upload')
        // return {
        //     url: URL.createObjectURL(file)
        // }
        const _this = this;
        let uploadUrlData = {};
        if(file.size > 1 * 1024 * 1024){
            let newFiles = {};
            await new Promise((resolve)=>{
                let r = new FileReader();  //本地预览
                r.readAsDataURL(file)
                r.onload = () => {
                    let src=r.result
                    const img = new Image();
                    img.src = src;
                    img.onload = () => {
                        const W = img .width;
                        const h = img. height;
                        const quality = 0.7; 
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext( '2d');
                        //创建属性节点
                        const anw = document.createAttribute("width");anw.nodeValue = W;
                        const anh = document.createAttribute("height");anh.nodeValue = h;
                        canvas . setAttributeNode(anw);
                        canvas. setAttributeNode(anh);
                        //底色PNG转JPEG时透明区域会变黑色
                        ctx.fillStyle="#fff";
                        ctx.fillRect(0, 0, W, h);
                        ctx.drawImage(img, 0, 0, W, h);
                        //quality値越小, 所绘制出的图像越横楜
                        const base64 = canvas.toDataURL( file.type, quality);
                        let bold = _this.dataURLtoBlob(base64);
                        resolve(newFiles = _this.blobToFile(bold, file.name))
                    }
                }
            }).then(async newFilesData => {
                let data = new FormData();
                data.append(objName, newFilesData, newFilesData.name);
                const baseUrl = process.env.NODE_ENV === 'production' ? apiUrl : '/api';
                await new Promise(resolve => {
                    fetch(baseUrl + api.uploadGoodsCommentFile, {
                        method: 'post',
                        body: data,
                    })
                        .then(res => res.json())
                        .then(urlData => {
                            resolve(uploadUrlData = urlData);
                        }).catch(err=>console.log(err))
                })
            });
        }else{
            let data = new FormData();
            data.append(objName, file, file.name);
            const baseUrl = process.env.NODE_ENV === 'production' ? apiUrl : '/api';
            
            await new Promise(resolve => {
                fetch(baseUrl + api.uploadGoodsCommentFile, {
                    method: 'post',
                    body: data,
                })
                    .then(res => res.json())
                    .then(urlData => {
                        // console.log(urlData,'<1 urlData')
                        resolve(uploadUrlData = urlData);
                    }).catch(err=>console.log(err))
            })
        }
        await sleep(2000)
        return {
            url: uploadUrlData?.data[0].ImageUrl,
        }
    }

    renderFormTable(){
        const { hasError, title, content, imageFiles } = this.state;
        // const { getFieldProps } = this.props.form;
        return(
            <List className="form-box">
                <InputItem
                    type="text"
                    placeholder="请输入文档标题"
                    error={hasError}
                    onErrorClick={this.onErrorClick}
                    onChange={this.onChangeTitle}
                    value={title}
                ></InputItem>
                <TextareaItem
                    className="word-content"
                    value={content}
                    placeholder="请输入文档内容"
                    autoHeight
                    onChange={this.onChangeContent}
                    // {...getFieldProps('count', {
                    //     initialValue: null,
                    // })}
                    // count={100}
                />
                {/* <ImagePicker
                    className="update-images"
                    files={files}
                    onChange={this.onChangeImage}
                    onImageClick={(index, fs) => console.log(index, fs)}
                    selectable={files.length <= 5}
                    multiple={true}
                /> */}
                <div className='imageUploadBox' style={{paddingLeft: '20px'}}>
                    <ImageUploader
                        maxCount={5}
                        multiple
                        style={{ '--cell-size': '90px !important' }}
                        value={imageFiles}
                        onChange={(_f)=>{
                            this.setState({
                                imageFiles: _f
                            })
                        }}
                        upload={(file)=>this.userImageUpload(file, 'imageFiles')}
                    />
                </div>
            </List>
        )
    }

    onCheckDepartment(e){
        this.setState({
            checkDepartment: e
        },()=>{
            this.getFileTypeList()
        })
    }
    onCheckFileType(e){
        this.setState({
            checkFileType: e
        })
    }

    renderPostTo(){
        const { DepartmentList, checkDepartment, fileTypeList, checkFileType } = this.state;
        const { getFieldProps } = this.props.form;
        return (
            <List renderHeader={() => '发布到'} className="post-to">
                <div>
                    <Item
                        extra={<Switch className='switch-button' color="#DCA55E" {...getFieldProps('switchBtn', { initialValue: true, valuePropName: 'checked' })} />}
                    >知识库</Item>
                    <Picker
                        data={DepartmentList}
                        value={checkDepartment}
                        cols={1}
                        onChange={this.onCheckDepartment.bind(this)}
                    >
                        <Item arrow="horizontal">知识库分类</Item>
                    </Picker>
                    {fileTypeList.length>0 && (<Picker
                        disabled={fileTypeList.length===0}
                        data={fileTypeList}
                        value={checkFileType}
                        cols={1}
                        onChange={this.onCheckFileType.bind(this)}
                    >
                        <Item arrow="horizontal">文件类型</Item>
                    </Picker>)}
                </div>
            </List>
        )
    }

    renderAdvancedSetting(){
        const { DepartmentList, checkPermission, saveTypeList, checkSaveType } = this.state;
       
        return (
            <List renderHeader={() => '高级设置'} className="advanced-setting">
                <div style={{paddingBottom: '10px'}}>
                    <Item>访问权限</Item>
                    <Checkbox.Group
                        value={checkPermission}
                        onChange={(val) => {
                            this.setState({
                                checkPermission: val
                            })
                        }}
                    >
                        <Space className="checkPermissionBox" wrap>
                            {
                                DepartmentList.map((item, index)=>{
                                    return(
                                        <Checkbox key={index} value={item.value}>
                                            {item.label}
                                        </Checkbox>
                                    )
                                })
                            }
                        </Space>
                    </Checkbox.Group>
                    {/* <Picker
                        data={saveTypeList}
                        value={checkSaveType}
                        cols={1}
                        onChange={()=>{}}
                    >
                        <Item arrow="horizontal">保存格式</Item>
                    </Picker> */}
                </div>
            </List>
        )
    }

    async submit(){
        const _this = this;
        const { imageFiles, title, content, checkDepartment, checkFileType, checkPermission } = this.state;

        if (!title || !content) {
            Toast.show({
                content: '标题和正文内容不能为空',
                maskClickable: false
            })
            return false;
        }

        let imageArr = imageFiles.map((item, index)=>{
            return `<img key='${index}' src='${item.url}' />`
        });
        

        let imgContent = imageArr.join("");
        //  <img src="../../assets/images/comment/alreadyCollected.png" />
        let infoContent= `<div style="font-size: 15px;font-family: PingFang SC;font-weight: 500;color: #262626;margin-bottom: 17px;"><div class="content">${content}</div><div style="margin-top: 10px;width: 100%;">${imgContent}</div></div>`;

        const values = {
            MemberId: this.props.userInfo.Id,
            DepartmentId: checkDepartment[0],
            FileTypeId: checkFileType[0],
            InfoTitle: title,
            InfoContent: infoContent,
            CheckView: checkPermission.join(',')
        }

        Fetch.post(api.createPublishInfo, values).then(res=>{
            if(res.code === 200){
                Toast.show({
                    content: res.message,
                    maskClickable: false,
                    afterClose: ()=>{
                        _this.props.history.goBack();
                    }
                })
            }else{
                Toast.show({
                    content: res.message,
                    maskClickable: false
                })
            }
        })
        
    }
 
    render() {
        // const {abcd}=this.state;
        return (
            <div className="form_table">
                {process.env.NODE_ENV === 'production' ? null : <NavTab title="发帖" onClick={()=>{this.props.history.goBack();}}/>}
                {this.renderFormTable()}
                {this.renderPostTo()}
                {this.renderAdvancedSetting()}
                <div style={{width: '100%', textAlign: 'center'}} >
                    <button className='post_btn' onClick={()=>{this.submit()}}>发布</button>
                </div>
                {/* <div style={{backgroundColor: '#ffffff', width: '100%', padding: '0px 10px'}} dangerouslySetInnerHTML={{__html: abcd}}></div> */}
                {/* <div>
                    <div style={{fontSize: '15px', fontFamily: 'PingFang SC', fontWeight: 'bold', color: '#333333', marginBottom: '14px'}}>Title</div>
                    <div style={{fontSize: '15px', fontFamily: 'PingFang SC', fontWeight: '500', color: '#262626', marginBottom: '17px'}}>Conent</div>
                    <img src="" alt='pic'/>
                </div> */}
            </div>
        )
    }
}
ReleasePosts = connect(mapStateToProps)(ReleasePosts) || ReleasePosts;
export default createForm()(ReleasePosts)