import React from 'react'
import dayjs from 'dayjs';
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Toast, Popover, List, Image } from 'antd-mobile-v5'
// import TitleBox from '../../components/title-box';

import styles from './index.module.scss'

import commentLikeIcon from '../../assets/images/comment/comment-like.png';
import commentUnLikeIcon from '../../assets/images/comment/comment-unlike.png';
import noMoreComment from '../../assets/images/comment/no-more-comment.png';
import replyIcon from '../../assets/images/comment/comment-reply.png';
import event from '../../utils/event';

var timeOutEvent = 0;
var longClick =0;
var touchY = 0;

class CommentList extends React.Component {
    state={
        isLikeOn: false,
    }
    
    componentDidMount(){
        // componentDidMount
        // componentDidUpdate
        setTimeout(()=>{
            let classArr = document.getElementsByClassName('comment-content');

            classArr?.length > 0 && [...classArr].map(domItem=>{
                domItem.addEventListener("touchstart", (e) => {
                    // 执行滚动回调
                    this.touchStart(e)
                },false)
                domItem.addEventListener("touchmove", (e) => {
                    // 执行滚动回调
                    this.touchMove(e)
                },false)
            })
        },1000)
    }
    componentDidUpdate(){
        setTimeout(()=>{
            let classArr = document.getElementsByClassName('comment-content');
            classArr?.length > 0 && [...classArr].map(domItem=>{
                domItem.addEventListener("touchstart", (e) => {
                    // 执行滚动回调
                    this.touchStart(e)
                },false)
                domItem.addEventListener("touchmove", (e) => {
                    // 执行滚动回调
                    this.touchMove(e)
                },false)
            })
        },1000)
    }

    touchStart(e){
        longClick = 0;
        timeOutEvent = setTimeout(()=>{
            longClick = 1;
        },700);
        e.preventDefault();
        // var touch = e.touches[0];
        // touchY = touch.clientY;
    }
    touchMove(e){
        clearTimeout(timeOutEvent); 
        timeOutEvent = 0;
        e.preventDefault();
        // var touch = e.touches[0]
        // if(Math.abs(touch.clientY - touchY) < 10){
        //     e.preventDefault();
        // }
    }
    async touchEnd(item){
        clearTimeout(timeOutEvent); 
        await this.props.getCommentItem && this.props.getCommentItem(item);
        if(timeOutEvent !== 0 && longClick === 0){
            if(item?.CommentLevel === 2){
                this.props.openCommentPopup && this.props.openCommentPopup();
            }
        }else if(timeOutEvent !== 0 && longClick !== 0){
            this.props.deleteComment && this.props.deleteComment()
        }
        return false;
    }
    async onLike(item){
        await this.props.getCommentItem && this.props.getCommentItem(item);
        this.props.onLike && this.props.onLike()
    }
    
    render() {
        const {
            commentArry,
            openCommentPopup,
            getCommentItem,
            deleteComment,
            onLike,
            callBack,
        } = this.props;
        // console.log(commentArry)
        const { isLikeOn } = this.state;

        return commentArry?.length > 0 ? (
            <List className={classNames(
                styles['comment-module'],
                'comment-module'
            )}>
                {
                    commentArry.map(item=>{
                        return (
                            <List.Item
                                className='comment-module-item'
                                key={item.Id}
                                title={
                                    <div className='module-top'>
                                        <div className='top'>
                                            <div className='nick-name'>{item?.NickName}</div>
                                            <div className={classNames('praise-number',{'isLikeOn': item?.Praise })} onClick={()=>{this.onLike(item)}}>
                                                <Image
                                                    src={ item?.Praise && commentLikeIcon || commentUnLikeIcon }
                                                    style={{ marginRight: 7 }}
                                                    width={16}
                                                    height={14}
                                                />{item?.PraiseNum}
                                            </div>
                                        </div>
                                        <div className='bottom'>{dayjs(item?.AddDate).format('YYYY-MM-DD HH:mm')}</div>
                                    </div>
                                }
                                prefix={
                                    <Image
                                        src={item?.AvatarUrl}
                                        style={{ borderRadius: 20 }}
                                        fit='cover'
                                        width={34}
                                        height={34}
                                    />
                                }
                                description={
                                    <div>
                                        {item?.list?.length>0 ? (<List className={classNames(
                                            styles['comment-module'],
                                            'comment-module'
                                        )} style={{background: '#F7F9FA', borderRadius: 8, border: 'none'}}>
                                            {
                                                item?.list.map(listItem=>{
                                                    return (
                                                        <List.Item
                                                            className='comment-module-item'
                                                            key={listItem.Id}
                                                            title={
                                                                <div className='module-top'>
                                                                    <div className='top'>
                                                                        <div className='nick-name'>{listItem?.NickName}</div>
                                                                        {/* <div className={classNames('praise-number',{'isLikeOn': listItem?.Praise })} >
                                                                            <Image
                                                                                src={ listItem?.Praise && commentLikeIcon || commentUnLikeIcon }
                                                                                style={{ marginRight: 7 }}
                                                                                width={16}
                                                                                height={14}
                                                                            />{listItem?.PraiseNum}
                                                                        </div> */}
                                                                    </div>
                                                                    <div className='bottom'>{dayjs(listItem?.AddDate).format('YYYY-MM-DD HH:mm')}</div>
                                                                </div>
                                                            }
                                                            prefix={
                                                                <Image
                                                                    src={listItem?.AvatarUrl}
                                                                    style={{ borderRadius: 20 }}
                                                                    fit='cover'
                                                                    width={34}
                                                                    height={34}
                                                                />
                                                            }
                                                        >
                                                            {/* onTouchEnd={this.touchEnd.bind(this)} onClick={()=>{getCommentItem && getCommentItem(listItem);openCommentPopup && openCommentPopup()}} */}

                                                            <div className='comment-content' onTouchEnd={this.touchEnd.bind(this, listItem)}>
                                                                <div className='release-box'>回复@<p className='release-name'>{listItem?.NickName}</p></div>
                                                                <div>
                                                                    {listItem?.CommentDesc}
                                                                </div>
                                                            </div>
                                                        </List.Item>
                                                    )
                                                })
                                            }
                                        </List>) : null}
                                        <div className='reply' onClick={()=>{getCommentItem && getCommentItem(item);openCommentPopup && openCommentPopup()}}>
                                            <Image src={ replyIcon } style={{marginRight: 8}} width={19.5} height={16} />
                                            回复
                                        </div>
                                    </div>
                                }
                            >
                                <div
                                    className='comment-content'
                                    style={{marginBottom: 11}}
                                    // onTouchStart={this.touchStart.bind(this)}
                                    // onTouchMove={this.touchMove.bind(this)}
                                    onTouchEnd={this.touchEnd.bind(this, item)}
                                >
                                    {item?.CommentDesc}
                                </div>
                            </List.Item>
                        )
                    })
                }
            </List>
        ) : <Image src={noMoreComment}/>;
        // Loading 
    }
}

CommentList.defaultProps = {
    commentArry: [],
};
CommentList.propTypes = {
    commentArry: PropTypes.array,
    callBack: PropTypes.func,
    onLike: PropTypes.func,
};

export default CommentList
