import React from 'react'
import { connect } from 'react-redux';
import { Form, Selector, Button, Switch, TextArea, Toast, Space, List, Collapse, ImageViewer } from 'antd-mobile-v5'
// import entryDecorator from '../../hocs/entryDecorator'
import api from '../../utils/api'
import Fetch from '../../utils/fetch-config'
import { resetTimeType } from '../../hocs/getTime'

import './form-details.scss'

const mapStateToProps = store => ({
    userInfo: store.userInfo,
});

class FormDetailPage extends React.Component {
    formRef = React.createRef();
    state={
        formData: {},                   //列表
        resumeStatus: '',               //表单状态： 0应聘简历，1初试，2复试，3通过，4不通过
        score: [
            {label: '特优', value: 10},
            {label: '优', value: 8},
            {label: '良', value: 6},
            {label: '中', value: 4},
            {label: '差', value: 2},
        ],
        questionList: [],
        showUserImage: false
    }

    componentWillMount() {
        let formData = this.props.history.location.state;
        this.setState({
            formData,
            resumeStatus: formData.ResumeStatus
        },()=>{
            this.handleData(formData);
            if(parseInt(formData.ResumeStatus) === 1){
                this.getQuestionList(1)
            }else if(parseInt(formData.ResumeStatus) === 2){
                this.getQuestionList(2)
            }
        })
    }
    componentWillReceiveProps () {}
    componentWillUnmount () { }
    componentDidShow () { }
    componentDidHide () { }

    getQuestionList(questionType){
        Fetch.get(api.getQuestionList+`?Type=${questionType}`).then(res=>{
            this.setState({
                questionList: res.data
            })
        })
    }

    submitForm(formValue){
        const { formData } = this.state;
        const question = formValue['list'];
        let questionList = [];
        if(question){
            for (let key in question){
                let itemObj = {};
                itemObj.QuestionId = key;
                itemObj.DetailScore = question[key][0];
                questionList.push(itemObj)
            }
        }
        const values = {
            ...formValue,
            list: questionList,
            MemberId: this.props.userInfo.Id,
            ResumeId: formData.Id,
            FourAnswer: '',
            FirstMemberId: '00000000-0000-0000-0000-000000000000',
            SecondMemberId: '00000000-0000-0000-0000-000000000000',
            CommentType: formData.ResumeStatus,
        }

        Fetch.post(api.updateResumeInfoStatus, values).then(res=>{
            Toast.show({
                content: res.message,
                maskClickable: false,
                afterClose: ()=>{
                    this.props.history.goBack()
                }
            })
        })
    }

    async handleData(formData){
        let dataArr = []
        for (var val in formData){
            let itemObj = {};
            let keyName = await this.getKeyNames(val);
            if(keyName){
                if(val === 'Marry'){
                    itemObj.sort = this.getKeySort(val);
                    itemObj.key = val;
                    itemObj.keyName = keyName;
                    itemObj.content = formData[val] ? '已婚' : '未婚';
                    dataArr.push(itemObj)
                }else if(val === 'Sex'){
                    itemObj.sort = this.getKeySort(val);
                    itemObj.key = val;
                    itemObj.keyName = keyName;
                    itemObj.content = formData[val] === 1 ? '男' : '女';
                    dataArr.push(itemObj)
                }else{
                    itemObj.sort = this.getKeySort(val);
                    itemObj.key = val;
                    itemObj.keyName = keyName;
                    itemObj.content = formData[val] ? formData[val] : '';
                    dataArr.push(itemObj)
                }
            }
        }
        let newForm = dataArr.sort((prev,cur)=>prev?.sort - cur?.sort);
        this.setState({
            formKeysArr:newForm
        })
    }

    getKeyNames(key){
        switch(key){
            case 'UserImageUrl':
                return '头像'
                break;
            case 'RealName':
                return '姓名'
                break;
            case 'Sex':
                return '性别'
                break;
            case 'Age':
                return '年龄'
                break;
            case 'Birthday':
                return '出生日期'
                break;
            case 'Marry':
                return '婚否'
                break;
            case 'Native':
                return '籍贯'
                break;
            case 'Nation':
                return '民族'
                break;
            case 'Education':
                return '学历'
                break;
            case 'SchoolName':
                return '毕业学校'
                break;
            case 'Major':
                return '专业'
                break;
            case 'Politics':
                return '政治面貌'
                break;
            case 'Major':
                return '专业'
                break;
            case 'Mobile':
                return '联系方式'
                break;
            case 'Height':
                return '身高(cm)'
                break;
            case 'Weight':
                return '体重(kg)'
                break;
            case 'Vision':
                return '视力'
                break;
            case 'IdCard':
                return '身份证号码'
                break;
            case 'IdCardAddress':
                return '身份证地址'
                break;
            case 'NativeAddress':
                return '籍贯详细地址'
                break;
            case 'list':
                return '教育经历'
                break;
            case 'work':
                return '工作经历'
                break;
            case 'OneAnswer':
                return '了解渠道'
                break;
            case 'TwoAnswer':
                return '之前的真实月工资'
                break;
            case 'ThreeAnswer':
                return '期望薪资'
                break;
            case 'FourAnswer':
                return '首选工作地点'
                break;
            case 'FourAnswers':
                return '次选工作地点'
                break;
            case 'JustIdCardUrl':
                return '身份证正面照'
                break;
            case 'BackIdCardUrl':
                return '身份证反面照'
                break;
            case 'Recommend':
                return '推荐人'
                break;
            default:
                return ''
                break;
        }
    }
    getKeySort(key){
        switch(key){
            case 'UserImageUrl':
                return '0'
                break;
            case 'RealName':
                return '1'
                break;
            case 'Sex':
                return '2'
                break;
            case 'Age':
                return '3'
                break;
            case 'Birthday':
                return '4'
                break;
            case 'Marry':
                return '5'
                break;
            case 'Native':
                return '6'
                break;
            case 'Nation':
                return '7'
                break;
            case 'Education':
                return '8'
                break;
            case 'SchoolName':
                return '9'
                break;
            case 'Major':
                return '10'
                break;
            case 'Politics':
                return '11'
                break;
            case 'Major':
                return '12'
                break;
            case 'Mobile':
                return '13'
                break;
            case 'Height':
                return '14'
                break;
            case 'Weight':
                return '15'
                break;
            case 'Vision':
                return '16'
                break;
            case 'IdCard':
                return '17'
                break;
            case 'IdCardAddress':
                return '18'
                break;
            case 'NativeAddress':
                return '19'
                break;
            case 'list':
                return '20'
                break;
            case 'work':
                return '21'
                break;
            case 'OneAnswer':
                return '22'
                break;
            case 'TwoAnswer':
                return '23'
                break;
            case 'ThreeAnswer':
                return '24'
                break;
            case 'FourAnswer':
                return '25'
                break;
            case 'FourAnswers':
                return '26'
                break;
            case 'JustIdCardUrl':
                return '27'
                break;
            case 'BackIdCardUrl':
                return '28'
                break;
            case 'Recommend':
                return '29'
                break;
            default:
                return ''
                break;
        }
    }

    renderFormData(){
        const { formKeysArr, showUserImage } = this.state;
        return(
            <List>
                {formKeysArr.length > 0 && formKeysArr.map(objData=>{
                    if(objData.key === 'list' || objData.key === 'work'){
                        return(
                            <List.Item key={objData.key}>
                                <div className='listTitle'>{objData.keyName+'：'}</div>
                                {objData.content.length > 0 ? (<Collapse accordion>
                                        {objData.content && objData.content.length > 0 && objData.content.map((item,index)=>{
                                            return (
                                                <Collapse.Panel key={item.Id} title={`第${index+1}项`}>
                                                    <List.Item>
                                                        <Space>
                                                            <div>开始日期：</div>
                                                            <div>{resetTimeType(item.BeginDate,'YY-mm-dd')??''}</div>
                                                        </Space>
                                                    </List.Item>
                                                    <List.Item>
                                                        <Space>
                                                            <div>结束日期：</div>
                                                            <div>{resetTimeType(item.EndDate,'YY-mm-dd')??''}</div>
                                                        </Space>
                                                    </List.Item>
                                                    <List.Item>
                                                        <Space>
                                                            <div>{objData.key === 'list' ? '学校(培训机构)名称：' : '工作单位名称：'}</div>
                                                            <div>{item.InfoName??''}</div>
                                                        </Space>
                                                    </List.Item>
                                                    <List.Item>
                                                        <Space>
                                                            <div>{objData.key === 'list' ? '专业：' : '职务：'}</div>
                                                            <div>{item.PositionName??''}</div>
                                                        </Space>
                                                    </List.Item>
                                                    {objData.key === 'list' && (
                                                        <List.Item>
                                                            <Space>
                                                                <div>所获证书：</div>
                                                                <div>{item.DiplonaName??''}</div>
                                                            </Space>
                                                        </List.Item>
                                                    )}
                                                </Collapse.Panel>
                                            )
                                        })}
                                </Collapse>) : '未填写'}
                            </List.Item>
                        )
                    }else if(objData.key === 'UserImageUrl'){
                        return objData.content && (
                            <List.Item key={objData.key}>
                                <Space>
                                    <div className='listTitle'>{objData.keyName+'：'}</div>
                                    <div
                                        className='listContent'
                                        onClick={()=>this.setState({showUserImage: true})}
                                        style={{width: '80px',height: '80px'}}
                                    >
                                        <img style={{objectFit: 'cover',width: '100%',height: '100%'}} src={objData.content}/>
                                    </div>
                                    <ImageViewer
                                        image={objData.content}
                                        visible={showUserImage && objData.content}
                                        onClose={() => {
                                            this.setState({
                                                showUserImage: false
                                            })
                                        }}
                                    />
                                </Space>
                            </List.Item>
                        )
                    }else if(objData.key === 'JustIdCardUrl' || objData.key === 'BackIdCardUrl'){
                        return objData.content && (
                            <List.Item key={objData.key}>
                                <Space>
                                    <div className='listTitle'>{objData.keyName+'：'}</div>
                                    <div
                                        className='listContent'
                                        onClick={()=>this.setState({showUserImage: true})}
                                        style={{width: '80px',height: '80px'}}
                                    >
                                        <img style={{objectFit: 'cover',width: '100%',height: '100%'}} src={objData.content}/>
                                    </div>
                                    <ImageViewer
                                        image={objData.content}
                                        visible={showUserImage && objData.content}
                                        onClose={() => {
                                            this.setState({
                                                showUserImage: false
                                            })
                                        }}
                                    />
                                </Space>
                            </List.Item>
                        )
                    }else{
                        return (
                            <List.Item key={objData.key}>
                                <Space>
                                    <div className='listTitle'>{objData.keyName+'：'}</div>
                                    <div className='listContent'>{objData.content}</div>
                                </Space>
                            </List.Item>
                        )
                    }
                })}
            </List>
        )
    }
    renderInterviewForm(){
        const {  score, questionList } = this.state;
        return(
            <Form
                className="form_assess"
                ref={this.formRef}
                footer={
                    <Button className='submit-btn' block type='submit' color='primary'>
                        提交
                    </Button>
                }
                onFinish={(values)=>this.submitForm(values)}
            >
                <Form.Item name='list'>
                    {questionList && questionList.map(item=>{
                        return (
                            <Form.Item key={item.Id} label={`${item.CommentPoint}`} name={['list',`${item.Id}`]} rules={[{ required: true, message: '评分不能为空' }]}>
                                <Selector
                                    options={score}
                                    columns={5}
                                />
                            </Form.Item>
                        )
                    })}
                </Form.Item>
                <Form.Item label='考官评价' name='CommentContent' rules={[{ required: true, message: '评价不能为空' }]}>
                    <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                </Form.Item>
                <Form.Item label='是否通过' name='ResumeStatus' >
                    <Switch style={{ '--checked-color': '#DAAE65' }} />
                </Form.Item>
            </Form>
        )
    }
    
    render() {
        const { formData, resumeStatus, formKeysArr } = this.state;
        return (
            <div>
                <h3>简历详情</h3>
                {(formKeysArr && formKeysArr.length > 0) && this.renderFormData()}
                {/* {resumeStatus > 0 && this.renderHRAppraise()} */}
                {((resumeStatus === 1 && formData.FirstHR === this.props.userInfo.Id) || resumeStatus === 2 && formData.SecondHR === this.props.userInfo.Id) && this.props.userInfo.Interviewer && this.renderInterviewForm()}
            </div>
        )
    }
}

FormDetailPage = connect(mapStateToProps)(FormDetailPage) || FormDetailPage;
// FormDetailPage = entryDecorator(FormDetailPage) || FormDetailPage;
export default FormDetailPage