import { createStore, applyMiddleware } from 'redux'
import { loadState, saveState } from './localstorage'
import { _version } from '../constants/version'
import reducers from '../reducers'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'

const persistedState = loadState();

console.log(persistedState,'pre')

const middleWares = [];
middleWares.push(thunk);
const loggerMiddleware = createLogger({
    predicate: () => process.env.NODE_ENV === 'development',
});
middleWares.push(loggerMiddleware);

const store = createStore(
    reducers,
    persistedState,
    applyMiddleware(...middleWares)
);

window.onbeforeunload = () => {
    store.dispatch({
        type: 'VERSION_UPDATE',
        payload: _version
    });
    saveState(store.getState());
};

export default store;