import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import { TabBar, Toast } from 'antd-mobile'
import icon_a_on from '../../assets/images/icons/icon-a-on.png'
import icon_a_off from '../../assets/images/icons/icon-a-off.png'
import icon_b_on from '../../assets/images/icons/icon-b-on.png'
import icon_b_off from '../../assets/images/icons/icon-b-off.png'
import icon_c_on from '../../assets/images/icons/icon-c-on.png'
import icon_c_off from '../../assets/images/icons/icon-c-off.png'
import icon_d_on from '../../assets/images/icons/icon-d-on.png'
import icon_d_off from '../../assets/images/icons/icon-d-off.png'
import icon_e_on from '../../assets/images/icons/icon-e-on.png'
import icon_e_off from '../../assets/images/icons/icon-e-off.png'

import './index.scss'

// interface IProps extends RouteComponentProps {}
// interface IState {}
class Tabbar extends Component {
    state={
        tabs:[
            {tabName:'首页', path: '/index', checkIcon: icon_a_on, unCheckIcon: icon_a_off},
            {tabName:'知识库', path: '/knowledge-base', checkIcon: icon_b_on, unCheckIcon: icon_b_off},
            {tabName:'论坛', path: '/forum', checkIcon: icon_c_on, unCheckIcon: icon_c_off},
            {tabName:'消息', path: '/message', checkIcon: icon_d_on, unCheckIcon: icon_d_off},
            {tabName:'我的', path: '/my', checkIcon: icon_e_on, unCheckIcon: icon_e_off},
        ],
        hidden: false
    };

    render() {
        const { tabs, hidden } = this.state;
        const pathname = this.props.location.pathname;
        
        return (
            <div className="tab" style={{
                display: !(pathname !== '/index' && pathname !== '/knowledge-base' && pathname !== '/forum' && pathname !== '/message' && pathname !== '/my') ? 'flex' : 'none'
            }}>
                <TabBar
                    unselectedTintColor="#C3C3C3"
                    tintColor="#D7AA78"
                    barTintColor="white"
                    hidden={hidden}
                >
                    {
                        tabs.map((item,index)=>{
                            return (
                                <TabBar.Item
                                    title={item.tabName}
                                    key={index}
                                    icon={
                                        <div className="tab-item-icon"><img src={item.unCheckIcon} alt=''/></div>
                                    }
                                    selectedIcon={
                                        <div className="tab-item-icon"><img src={item.checkIcon} alt=''/></div>
                                    }
                                    selected={pathname === item.path}
                                    onPress={(props) => {
                                        // this.props.history.replace(item.path);
                                        if(item.path==='/index' || item.path==='/knowledge-base' || item.path === '/my'){
                                            this.props.history.push(item.path);
                                        }else if(item.path === '/forum' || item.path === '/message'){
                                            Toast.info('待开发')
                                        }
                                        console.log(props)
                                        console.log(item)
                                    }}
                                >
                                </TabBar.Item>
                            )
                        })
                    }
                    
                </TabBar>
            </div>
        )
    }
}

export default withRouter(Tabbar);

