// import { _version } from '../constants/version'

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (serializedState === null) {
            return undefined;
        } else {
            let state = JSON.parse(serializedState);
            // if (state.version !== _version) {
            //     return undefined;
            // }
            return state;
        }
        
    } catch (e) {
        return undefined;
    }
};
export const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch (e) {

    }
};