import { UPDATE_DEPARTMENTLIST } from '../constants/department';
import { isEmptyObject } from '../utils/common';

let defaultState = localStorage.getItem('state') && JSON.parse(localStorage.getItem('state'));
const defaultDepartmentList = isEmptyObject(defaultState) ? {} : (defaultState && defaultState.departmentList);
export default function departmentList(state = defaultDepartmentList, action) {
    if (action.type === UPDATE_DEPARTMENTLIST) {
        return state = action.departmentList;
    }
    return state;
}