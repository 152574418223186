import React from 'react'
import { connect } from 'react-redux';
import { List, Grid } from 'antd-mobile-v5'
import api from '../../utils/api'
import Fetch from '../../utils/fetch-config'

import './index.scss'

import centerBg from '../../assets/images/my-center/center-bg.jpg'
import caiershi from '../../assets/images/my-center/caiershi.png'
import certificate from '../../assets/images/my-center/certificate.png'
import personalInformation from '../../assets/images/my-center/personal-information.png'
import achievement from '../../assets/images/my-center/achievement.png'
import paperCertificates from '../../assets/images/my-center/paper-certificates.png'
import collect from '../../assets/images/my-center/collect.png'
import questionnaire from '../../assets/images/my-center/questionnaire.png'
import record from '../../assets/images/my-center/record.png'
import rightArrow from '../../assets/images/my-center/right-arrow.png'

const mapStateToProps = store => ({
    userInfo: store.userInfo
});

class MyCenter extends React.Component {
    state={
        isCaiershi: false
    }
    componentWillMount() {
        this.existsUser()
    }
    componentWillReceiveProps () {}
    componentWillUnmount () { }
    componentDidShow () {}
    componentDidHide () { }

    async existsUser(){
        await Fetch.get(api.existsUser + `?memberId=${this.props.userInfo.Id}`).then(res=>{
            this.setState({
                isCaiershi: res?.data
            })
        })
    }

    toLink(linkUrl){
        this.props.history.push(linkUrl)
    }

    renderList(){
        return(
            <List className='meun-list'>
                <List.Item prefix={<img src={personalInformation} style={{width: '22px', height: '22px'}} alt='个人信息'/>} arrow={<img src={rightArrow} style={{width: '7px', height: '12px'}} alt='right-arrow'/>} onClick={() => {this.props.history.push('/my/personal-information');}}>
                    个人信息
                </List.Item>
                <List.Item prefix={<img src={achievement} style={{width: '22px', height: '22px'}} alt='考试成绩'/>} arrow={<img src={rightArrow} style={{width: '7px', height: '12px'}} alt='right-arrow'/>} onClick={() => {}}>
                    考试成绩
                </List.Item>
                <List.Item prefix={<img src={paperCertificates} style={{width: '22px', height: '22px'}} alt='资质证书'/>} arrow={<img src={rightArrow} style={{width: '7px', height: '12px'}} alt='right-arrow'/>} onClick={() => {}}>
                    资质证书
                </List.Item>
                <List.Item prefix={<img src={collect} style={{width: '22px', height: '22px'}} alt='我的收藏'/>} arrow={<img src={rightArrow} style={{width: '7px', height: '12px'}} alt='right-arrow'/>} onClick={() => {this.props.history.push('/my/collect');}}>
                    我的收藏
                </List.Item>
                <List.Item prefix={<img src={questionnaire} style={{width: '22px', height: '22px'}} alt='问卷调查'/>} arrow={<img src={rightArrow} style={{width: '7px', height: '12px'}} alt='right-arrow'/>} onClick={() => {}}>
                    问卷调查
                </List.Item>
                <List.Item prefix={<img src={questionnaire} style={{width: '22px', height: '22px'}} alt='简历测试'/>} arrow={<img src={rightArrow} style={{width: '7px', height: '12px'}} alt='right-arrow'/>} onClick={() => {this.props.history.push('/audition');}}>
                    简历测试
                </List.Item>
                {/* <List.Item prefix={<img src={record} style={{width: '22px', height: '22px'}}/>} arrow={<img src={rightArrow} style={{width: '7px', height: '12px'}} />} onClick={() => {}}>
                    浏览记录
                </List.Item> */}
            </List>
        )
    }

    render() {
        const { userInfo } = this.props;
        const { isCaiershi } = this.state;

        return (
            <div className='mycenter'>
                <div className='headerBox'>
                    <div className='bg' style={{
                        background: `url(${centerBg}) no-repeat center`
                    }}></div>
                    <div className='info'>
                        <div className='top'>
                            <div className='avatar'>
                                <img src={userInfo?.AvatarUrl} alt='用户头像'/>
                            </div>
                            <div className='infoContent'>
                                <div className='infoLeft'>
                                    <p className='user-name'>{ userInfo?.RealName || userInfo?.Nick}</p>
                                    {isCaiershi ? (<p className='lv-name'>
                                        资深采耳师
                                        <img style={{width: '14px', height: '14px',marginLeft: '5px'}} src={caiershi}/>
                                    </p>) : null}
                                </div>
                                {isCaiershi ? (<div className='infoRight'>
                                    <img src={certificate}/>
                                    毕业证书
                                </div>) : null}
                            </div>
                        </div>
                        <div className='bottom'>
                            <Grid columns={3} gap={8}>
                                <Grid.Item>
                                    <div>
                                        <div className='quantity'>0</div>
                                        <div className='title'>学时</div>
                                    </div>
                                </Grid.Item>
                                <Grid.Item>
                                    <div>
                                        <div className='quantity'>0</div>
                                        <div className='title'>学分</div>
                                    </div>
                                </Grid.Item>
                                <Grid.Item>
                                    <div>
                                        <div className='quantity'>0</div>
                                        <div className='title'>关注</div>
                                    </div>
                                </Grid.Item>
                            </Grid>
                        </div>
                    </div>
                </div>
                {this.renderList()}
            </div>
        )
    }
}

MyCenter = connect(mapStateToProps)(MyCenter) || MyCenter;
export default MyCenter