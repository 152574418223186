import React from 'react'
import api from '../../utils/api'
import Fetch from '../../utils/fetch-config'
import { Tabs } from "antd";
import { EyeOutlined, createFromIconfontCN } from '@ant-design/icons'
import { List } from 'antd-mobile'
import { resetTimeType } from '../../hocs/getTime'
import AtLoading from '../../components/load-more';
import { connect } from 'react-redux'
import entryDecorator from '../../hocs/entryDecorator'
import { isEmptyObject, getAllUrlParam } from '../../utils/common'
import { updateDepartment } from '../../actions/department'

import './index.scss';

const Item = List.Item;
const Brief = Item.Brief;
const { TabPane } = Tabs;

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_2736796_cuk0m7ucf3j.js',
});

const mapStateToProps = store => ({
    userInfo: store.userInfo,
    departmentList: store.departmentList
});

const mapDispatchToProps = dispatch => ({
    updateDepartment: departmentList => {
        dispatch(updateDepartment(departmentList));
    }
});

class KnowledgeBase extends React.Component {
    state={
        pageRoutes: [],
        paramsDepartmentId: null,
        paramsFileTypeId: null,
        checkDepartmentId: null,
        checkFileTypeId: null,
        contentList: [],
        listArr: [],
        pageIndex: 1
    };
    componentWillMount() {
        //toa   ?memberId=9b52e776-1840-40ab-998a-37135a09a0dd&appType=1
        //oa  ?memberId=533995bd-1f5e-4709-b88c-72317e4a9c0d&appType=1
        // const memberId = params.hasOwnProperty('memberId') && params.memberId;
        // &departmentId=2&fileTypeId=4
        const params = getAllUrlParam(this.props.location.search.slice(1));
        let defaultPageState = JSON.parse(localStorage.getItem('pageState')) ?? {};
        if(params.hasOwnProperty('departmentId') && params.hasOwnProperty('fileTypeId') || !isEmptyObject(defaultPageState)){
            this.setState({
                paramsDepartmentId: params.departmentId ?? defaultPageState.departmentId,
                paramsFileTypeId: params.fileTypeId ?? defaultPageState.fileTypeId,
            },()=>{
                if(params.hasOwnProperty('memberId')){
                    this.getDepartmentList(params.memberId);
                }else{
                    this.getDepartmentList(this.props.userInfo && this.props.userInfo.Id);
                }
            })
        }else{
            if(params.hasOwnProperty('memberId')){
                this.getDepartmentList(params.memberId);
            }else{
                this.getDepartmentList(this.props.userInfo && this.props.userInfo.Id);
            }
        }
    }
    componentWillReceiveProps () {}
    componentWillUnmount () {
    }
    componentDidShow () {
    }
    componentDidHide () { }

    changeURLStatic(name, value) {
        var url = window.location.href;
        var reg = eval('/([\?|&]'+name+'=)[^&]*/gi');
        value = value.toString().replace(/(^\s*)|(\s*$)/g,"");  //移除首尾空格
        if(!value){
            var url2 = url.replace(reg , '');  //正则替换
        }else{
            if(url.match(reg)){
                var url2 = url.replace(reg , '$1' + value);  //正则替换
            }else{
                var url2 = url + (url.indexOf('?') > -1 ? '&' : '?') + name + '=' +value;  //没有参数添加参数
            }
        }
        window.history.replaceState(null,null, url2);  //替换地址栏
    }

    handleChange(e) {
        const {checkDepartmentId} = this.state
        this.setState({
            checkDepartmentId: e ?? checkDepartmentId,
        },()=>{
            this.changeURLStatic('departmentId', e ?? checkDepartmentId)
            this.getFileTypeList();
        })
    };
    changeFileType(e) {
        const {checkFileTypeId} = this.state
        this.setState({
            checkFileTypeId: e ?? checkFileTypeId,
        },()=>{
            this.changeURLStatic('fileTypeId', e ?? checkFileTypeId)
            this.getPublishInfoListPage();
        })
    };

    async getDepartmentList(Id){
        const { paramsDepartmentId } = this.state;
        let memberId = Id ? Id : this.props.userInfo.Id;
        let res = await Fetch.get(api.getDepartmentList+`?MemberId=${memberId}`)
        this.setState({
            pageRoutes: res.data && res.data,
            checkDepartmentId: paramsDepartmentId ?? (res.data && res.data.length > 0 && res.data[0].DepartmentId)
        },()=>{
            var stateDepartment = (res && res.data) ? res.data : [];
            this.props.updateDepartment(stateDepartment);
            this.handleChange(paramsDepartmentId ?? res.data[0].DepartmentId.toString());
        })
    }
    
    async getFileTypeList(){
        const { paramsDepartmentId, checkDepartmentId, paramsFileTypeId } = this.state;
        let res = await Fetch.get(api.getFileTypeList+`?DepartmentId=${checkDepartmentId}`)
        this.setState({
            contentList: res.data && res.data,
            checkFileTypeId: paramsFileTypeId ?? (res.data && res.data.length > 0 ? res.data[0].Id : null)
        },()=>{
            if(paramsDepartmentId === checkDepartmentId){
                this.changeFileType(paramsFileTypeId);
            }else{
                this.changeFileType(res.data[0].Id.toString());
            }
        })
    }

    async getPublishInfoListPage(){
        const { checkDepartmentId, checkFileTypeId, pageIndex } = this.state;
        await Fetch.post(api.getPublishInfoListPageV2, {
            CommentStatus: 1,
            MemberId: this.props.userInfo.Id,
            DepartmentId: checkDepartmentId,
            FileTypeId: checkFileTypeId,
            PageIndex: pageIndex,
        }).then(res => {
            this.setState({
                listArr: res.data?.List,
            })
        })
    }

    getWatchCount(id){
        Fetch.post(api.createCommentLikes,{
            ArticleId: id, // 评论id
            MemberId: this.props.userInfo.Id,
            Status: 3, // 1点赞 2收藏 3查看
            Type: 1, // 1文章 2评论 3其他
            OperationType: 1, // 1新增 2修改
        })
    }

    onLinkDetailPage(obj) {
        const {checkDepartmentId,checkFileTypeId} = this.state;
        this.getWatchCount(obj.Id)
        let state = {
            departmentId: checkDepartmentId,
            fileTypeId: checkFileTypeId
        }
        let objState = JSON.stringify(state);
        localStorage.setItem('pageState', objState);
        this.props.history.push({ pathname: `/knowledge-base/detailPages/${obj.Id}`, state: obj });
    }

    onRelease(){
        let obj = {MemberId: this.props.userInfo.Id};
        this.props.history.push({ pathname: `/knowledge-base/release-posts`, state: obj });
    }

    renderContent(){
        const { contentList, listArr, checkFileTypeId } = this.state;

        return(
            <div className="content-box">
                <Tabs type="card" activeKey={checkFileTypeId} onTabClick={this.changeFileType.bind(this)}>
                    {
                        (contentList && contentList.length > 0) && contentList.map((tabsItem,index)=>{
                            return (
                                <TabPane tab={`${tabsItem.FileTypeName}`} key={(tabsItem.Id).toString()}>
                                    {
                                        (listArr && listArr.length > 0) && listArr.map((listItem,listIndex)=>{
                                            return (
                                                <List className="my-list" key={listIndex} renderFooter={() => {
                                                    // return(
                                                    //     <AtLoading status="noMore" noMoreText="已显示全部内容" />
                                                    // )
                                                }}>
                                                    {/* <Item arrow="horizontal" onClick={() => {this.onLinkDetailPage(listItem)}}>{listItem.InfoTitle}</Item> */}
                                                    {/* <Item extra={<img style={{width:'100%', height: '100%', objectFit: 'cover'}} src={listItem.Images} />} align="top" multipleLine wrap onClick={() => {this.onLinkDetailPage(listItem)}}> */}
                                                    <Item align="top" multipleLine wrap onClick={() => {this.onLinkDetailPage(listItem)}}>
                                                        <div className="list-title">
                                                            {listItem.Images && <img style={{marginRight: '10px'}} src={listItem.Images} />}
                                                            {listItem.InfoTitle}
                                                        </div>
                                                        <Brief style={{ marginTop: '9px', marginRight: '30px'}}>
                                                            <div className='list-info'>
                                                                <div className="left">{resetTimeType(listItem.BeginDate)}</div>
                                                                <div className="right"><EyeOutlined style={{marginRight: '6px'}}/>{listItem.LookCount}</div>
                                                            </div>
                                                        </Brief>
                                                    </Item>
                                                </List>
                                            )
                                        })
                                    }
                                </TabPane>
                            )
                        })
                    }
                </Tabs>
            </div>
        )
    }

    render() {
        const { pageRoutes, checkDepartmentId } = this.state;
        return (
            <div className="tabs_box">
                <Tabs  tabPosition='top' activeKey={checkDepartmentId} onTabClick={this.handleChange.bind(this)} style={{ minHeight: 'calc(100vh - 56px)' }}>
                    {(pageRoutes && pageRoutes.length > 0) && pageRoutes.map((item, index) => (
                        <TabPane tab={item.DepartmentName} key={(item.DepartmentId).toString()}>
                            {this.renderContent()}
                        </TabPane>
                    ))}
                </Tabs>
                <div className='release' onClick={this.onRelease.bind(this)}>
                    <IconFont type='bianji' style={{fontSize: '25px', color: '#FFFFFF'}}/>
                </div>
            </div>
        )
    }
}
KnowledgeBase = connect(mapStateToProps, mapDispatchToProps)(KnowledgeBase) || KnowledgeBase;
KnowledgeBase = entryDecorator(KnowledgeBase) || KnowledgeBase;
export default KnowledgeBase