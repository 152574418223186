import React from 'react'
import { connect } from 'react-redux';
import api from '../../utils/api'
import Fetch from '../../utils/fetch-config'
import { List, Button, Dialog, Input, Empty, Toast, Loading, Image, Tabs, Popup, Grid } from 'antd-mobile-v5'
import { AddOutline, EyeOutline, DeleteOutline, FolderOutline, EditSOutline } from 'antd-mobile-icons'
import { sleep } from 'antd-mobile-v5/es/utils/sleep'
import dayjs from 'dayjs';


import './collect.scss'


const mapStateToProps = store => ({
    userInfo: store.userInfo
});

class Collect extends React.Component {
    state={
        isShow: false,
        isShowHandlePopup: false,
        fileName: null,
        fileList: [],
        pageIndex: 1,
        collectList: [],
        checkCollectFileId: null,
        isDelete: false,
        deleteId: null,
    }
    componentWillMount() {
        this.getFileCollectionRecordList()
    }
    componentWillReceiveProps () {}
    componentWillUnmount () {}
    componentDidShow () {}
    componentDidHide () {}

    async getFileCollectionRecordList(){
        await Fetch.get(api.getFileCollectionRecordList + `?MemberId=${this.props.userInfo?.Id}`).then(res=>{
            this.setState({
                fileList: res.data,
                checkCollectFileId: (res.data?.[0]?.Id).toString()
            },()=>{
                this.getMemberCollectionRecord(this.state.checkCollectFileId)
            })
        })
    }

    async getMemberCollectionRecord(fileType){
        // console.log(typeof(fileType))
        if(!fileType){
            return;
        }
        await sleep(500)
        const { pageIndex } = this.state
        Fetch.get(api.getMemberCollectionRecord + `?MemberId=${this.props.userInfo?.Id}&FileType=${fileType}&PageIndex=${pageIndex}`).then(res=>{
            this.setState({
                collectList: res.data?.List ?? [],
                checkCollectFileId: fileType
            })
        })
    }

    changeInput(e){
        this.setState({
            fileName: e
        })
    }

    changeFileName(e){
        if (e === '' || e === null || e === undefined){
            Toast.show({
                content: '名称不能为空',
                maskClickable: false,
            })
        } else {
            this.setState({
                fileName: e
            },()=>console.log(e))
        }
    }

    isCreatCollectFiles(){
        this.setState({
            isShow: true
        })
    }

    creatCollectFiles(id, fileType, fileStatus){
        const { fileName } = this.state;
        Fetch.post(api.addUpdateFileCollectionRecord, {
            Id: id ?? 0,
            FileName: fileName ?? '',
            MemberId: this.props.userInfo.Id ?? "00000000-0000-0000-0000-000000000000",
            FileType: fileType ?? 1,
            FileStatus: fileStatus ?? 1
        }).then(res=>{
            Toast.show({
                content: res.message,
                maskClickable: false,
                afterClose: ()=>{
                    this.setState({
                        isShowHandlePopup: false,
                        fileName: null
                    })
                    this.getFileCollectionRecordList();
                }
            });
        })
    }

    renderCollectFileList(){
        const {collectList} = this.state
        return collectList.length > 0 ? (
            <List>
                {collectList.map(listItem=>{
                    return (
                        <List.Item
                            key={(listItem.Id).toString()}
                            arrow={null}
                            prefix={
                                listItem.Images ? (<Image
                                    src={listItem.Images}
                                    style={{ marginTop: '7px' }}
                                    fit='cover'
                                    width={50}
                                    height={50}
                                />) : null
                            }
                            description={
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    fontSize: '12px'
                                }}>
                                    <div>{dayjs(listItem.BeginDate).format('MM-DD HH:mm')}</div>
                                    <div><EyeOutline />{listItem.LookCount}</div>
                                </div>
                            }
                            onClick={()=>{this.props.history.push({ pathname: `/knowledge-base/detailPages/${listItem.Id}`, state: listItem });}}
                        >
                            <p className='collectTitle'>{listItem.InfoTitle}</p>
                        </List.Item>
                    )
                })}
            </List> 
        ) : <Loading/>
    }

    renderCollectList(){
        const { fileList, checkCollectFileId, collectList } = this.state;
        return (
            <Tabs activeKey={checkCollectFileId} onChange={(e)=>{this.getMemberCollectionRecord(e)}}>
                {fileList.length > 0 && fileList.map(item=>{
                    return (
                        <Tabs.TabPane title={item.FileName} key={(item.Id).toString()}>
                            {
                                checkCollectFileId === (item?.Id).toString() && (
                                    collectList.length > 0 ? this.renderCollectFileList() : <Empty description='暂无收藏'/>
                                )
                            }
                        </Tabs.TabPane>
                    )
                })}
            </Tabs>
        )
    }

    
    renderHandleFilePopup(){
        const { isShowHandlePopup, fileList, fileName } = this.state;

        return (
            <Popup
                className='filePopup'
                visible={isShowHandlePopup}
                onMaskClick={() => {
                    this.setState({
                        isShowHandlePopup: false
                    })
                }}
                bodyStyle={{ minHeight: '30vh',padding: '18px', borderRadius: '18px 18px 0 0' }}
            >
                <Grid columns={1} gap={8}>
                    {fileList.map(item=>{
                        return (
                            <Grid.Item key={item.Id}>
                                <div className='fileItem'>
                                    {/* <p className='fileName'>{item?.FileName}</p> */}
                                    <div className='fileName'>
                                        <Input defaultValue={item?.FileName} placeholder='请输入收藏夹名称' onChange={(e)=>this.changeFileName(e)}/>
                                    </div>
                                    <Button onClick={()=>{this.creatCollectFiles(item.Id, 1, 2)}}><EditSOutline color='#76c6b8'/></Button>
                                    <Button onClick={()=>{
                                        this.changeInput(item?.FileName)
                                        Dialog.show({
                                            content: '提示：删除收藏夹后里面所收藏的内容也会被删除，该操作无法复原！',
                                            closeOnAction: true,
                                            actions: [
                                                [
                                                    {
                                                        key: 'cancel',
                                                        text: '取消',
                                                        bold: true,
                                                    },
                                                    {
                                                        key: 'delete',
                                                        text: '删除',
                                                        bold: true,
                                                        danger: true,
                                                        onClick:()=>this.creatCollectFiles(item.Id, 1, 3)
                                                    },
                                                ]
                                            ],
                                        })
                                    }}><DeleteOutline color='#D6A05E'/></Button>
                                </div>
                            </Grid.Item>
                        )
                    })}
                </Grid>
            </Popup>
        )
    }
    
    render() {
        const { isShow } = this.state;
        return (
            <div className='collectbox'>
                <div className='filesList'>
                    {this.renderCollectList()}
                </div>
                <Dialog
                    visible={isShow}
                    content={
                        <Input placeholder='请输入收藏夹名称' onChange={(e)=>this.changeInput(e)} />
                    }
                    closeOnAction
                    closeOnMaskClick
                    onClose={() => {
                        this.setState({isShow: false})
                    }}
                    actions={[
                        {
                            key: 'creat',
                            text: '创建',
                            onClick: ()=>this.creatCollectFiles()
                        },
                    ]}
                />
                <Button
                    className='creatFiles'
                    onClick={()=>this.isCreatCollectFiles()}
                >
                    <AddOutline /> 新建收藏夹
                </Button>
                <Button
                    className='handleFiles'
                    onClick={()=>{
                        this.setState({
                            isShowHandlePopup: true
                        })
                    }}
                >
                    <FolderOutline/>
                </Button>
                {this.renderHandleFilePopup()}
            </div>
        )
    }
}

Collect = connect(mapStateToProps)(Collect) || Collect;
export default Collect