import React from 'react'
import { connect } from 'react-redux'
import { Input, message, Badge, Divider } from 'antd'
import { SearchOutlined, BellOutlined, createFromIconfontCN, UnorderedListOutlined, EnvironmentOutlined } from '@ant-design/icons';
import TitleBox from '../../components/title-box';
import AtLoading from '../../components/load-more';
import { List, Toast, NoticeBar } from 'antd-mobile-v5';
import entryDecorator from '../../hocs/entryDecorator'
import api from '../../utils/api'
import Fetch from '../../utils/fetch-config'
import { resetTimeType } from '../../hocs/getTime'
import { RightOutline } from 'antd-mobile-icons'

import testIcon from '../../assets/images/common/test@2x.png';
import exercisesIcon from '../../assets/images/common/exercises@2x.png';
import baseIcon from '../../assets/images/common/base@2x.png';
import debunkIcon from '../../assets/images/common/debunk@2x.png';
import classIcon from '../../assets/images/common/class@2x.png';
import forumIcon from '../../assets/images/common/forum@2x.png';
import voteIcon from '../../assets/images/common/vote@2x.png';
import feedbackIcon from '../../assets/images/common/feedback@2x.png';
import hornIcon from '../../assets/images/common/horn.png';

import './index.scss';

const Item = List.Item;

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_2736796_cuk0m7ucf3j.js',
});

const mapStateToProps = store => ({
    userInfo: store.userInfo
});


class Index extends React.Component {
    state={
        count: 1,
        newclassList: [],
        newActivityList: [],
        newForumList: [],
        VoteStatus: 0,
        newVoteList: [],
        auditionPageindex: 1,
        auditionList: [],
    }

    componentWillMount() {
        // console.log(this.props,'p')
        this.getResumeInfoByMemberId();
        this.getVoteMainInfoRecord();
    }
    componentWillReceiveProps () {}
    componentWillUnmount () {}
    componentDidShow () {
    }
    componentDidHide () { }

    onSearch(value){
        message.success('成功')
    }

    onLinkUrl(url) {
        this.props.history.push({ pathname: `${url}`});
    }

    //最新投票
    getVoteMainInfoRecord(){
        const { VoteStatus } = this.state;
        const paramUrl = `?MemberId=${this.props.userInfo?.Id}&VoteStatus=${VoteStatus}`
        Fetch.get(api.getVoteMainInfoRecord + paramUrl).then(res=>{
            this.setState({
                newVoteList: res.data
            })
        });
    }

    renderGirdNav(){
        const { count } = this.state;
        return (
            <div className='header'>
                <div className='header_top'>
                    <div className='search_box'>
                        <Input placeholder="输入关键词搜索" className='search_input' prefix={<SearchOutlined  style={{ fontSize: '16px', color:'#AEAEAE' }}/>} />
                        <Badge dot={count} color='#FBC066'>
                            <BellOutlined style={{ fontSize: '18px', color:'#AEAEAE' }}/>
                        </Badge>
                    </div>
                    <div className='gird-nav'>
                        <div onClick={()=>{
                            Toast.show({content: '待开发'})
                            // this.onLinkUrl.bind(this,'/test');
                        }}>
                            <div className='icon_bg'>
                                <img style={{width: '100%', height: '100%', objectFit: 'cover'}} src={testIcon} />
                            </div>
                            考试
                        </div>
                        <div onClick={()=>{
                            Toast.show({content: '待开发'})
                            // this.onLinkUrl.bind(this,'/exercises')
                        }}>
                            <div className='icon_bg'>
                                <img style={{width: '100%', height: '100%', objectFit: 'cover'}} src={exercisesIcon} />
                            </div>
                            练习题
                        </div>
                        <div onClick={this.onLinkUrl.bind(this,'/knowledge-base')}>
                            <div className='icon_bg'>
                                <img style={{width: '100%', height: '100%', objectFit: 'cover'}} src={baseIcon} />
                            </div>
                            知识库
                        </div>
                        <div onClick={()=>{
                            Toast.show({content: '待开发'})
                            // this.onLinkUrl.bind(this,'/debunk');
                        }}>
                            <div className='icon_bg'>
                                <img style={{width: '100%', height: '100%', objectFit: 'cover'}} src={debunkIcon} />
                            </div>
                            吐槽
                        </div>
                        <div onClick={()=>{
                            Toast.show({content: '待开发'})
                            // this.onLinkUrl.bind(this,'/class');
                        }}>
                            <div className='icon_bg'>
                                <img style={{width: '100%', height: '100%', objectFit: 'cover'}} src={classIcon} />
                            </div>
                            课程
                        </div>
                        <div onClick={()=>{
                            Toast.show({content: '待开发'})
                            // this.onLinkUrl.bind(this,'/forum');
                        }}>
                            <div className='icon_bg'>
                                <img style={{width: '100%', height: '100%', objectFit: 'cover'}} src={forumIcon} />
                            </div>
                            论坛
                        </div>
                        <div onClick={this.onLinkUrl.bind(this,'/vote-list')}>
                            <div className='icon_bg'>
                                <img style={{width: '100%', height: '100%', objectFit: 'cover'}} src={voteIcon} />
                            </div>
                            投票
                        </div>
                        <div onClick={()=>{
                            Toast.show({content: '待开发'})
                            // this.onLinkUrl.bind(this,'/feedback');
                        }}>
                            <div className='icon_bg'>
                                <img style={{width: '50%', height: '50%', objectFit: 'cover'}} src={feedbackIcon} />
                            </div>
                            反馈
                        </div>
                    </div>
                </div>
                <Divider className='line' style={{marginBottom: 0}}/>
                <div className='message_box'>
                    <NoticeBar
                        icon={
                            <p className="notice-header">
                                <img style={{width: '20px'}} src={hornIcon} />
                                <span className="notice-title">开班预告</span>
                            </p>
                        }
                        extra={<RightOutline />}
                        content={''}
                        // color='alert'
                    />
                </div>
            </div>
        )
    }

    getResumeInfoByMemberId(){
        const { auditionPageindex } = this.state
        const paramUrl = `?MemberId=${this.props.userInfo.Id}&pageIndex=${auditionPageindex}&Type=1`
        Fetch.get(api.getResumeInfoByMemberId + paramUrl).then(res=>{
            this.setState({
                auditionList: res.data.List
            })
        })
    }
    toDetailPages(obj){
        this.props.history.push({ pathname: `/form-detailPage/${obj.Id}`, state: obj });
    }

    renderAudition(){
        const { auditionList } = this.state;
        return (
            <div className='new_message_list'>
                <TitleBox
                    className='recommend-box-title-box'
                    title={`最新简历`}
                    url='/audition-list'/>
                    {
                        auditionList && auditionList.length > 0 ? (
                            <div className='new_list'>
                                {auditionList.map((item,index)=>{
                                    return index <= 2 && (
                                        <List.Item
                                            key={item.Id}
                                            extra={<span style={{fontSize: '10px'}}>{resetTimeType(item.AddDate,'YY-mm-dd')}</span>}
                                            onClick={() => {this.toDetailPages(item)}}
                                        >
                                            <div className='new_list_title'>{item.RealName}</div>
                                        </List.Item>
                                    )
                                    // return(
                                    //     <div className='list' key={item.Id}>
                                    //         <div className='list_title'>{item.RealName}--{resetTimeType(item.AddDate,'YY-mm-dd HH:mm')}</div>
                                    //         <Divider className='line'/>
                                    //     </div>
                                    // )
                                })}
                            </div>
                        ) : (
                            <AtLoading status="noMore" noMoreText='暂无最新简历' />
                        )
                    }
            </div>
        )
    }

    renderLessonMessage(){
        const { newclassList } = this.state
        return (
            <div className='new_message_list'>
                <TitleBox
                    className='recommend-box-title-box'
                    title={`最新课程`}
                    url='#'/>
                {newclassList.length > 0 ? (<div className='new_list'>
                    <div className='list'>
                        <div className='list_title'>【公司宣传片】走进耳道，让我们成为一名优秀的采耳师啦啦啦萨拉斯</div>
                        <div className='list_info'>
                            <p>
                                <UnorderedListOutlined style={{fontSize: '11px', color: '#919090', marginRight: '7px'}}/>6节
                            </p>
                            <p>
                                <IconFont type='ico-user-group-off' style={{fontSize: '11px', color: '#919090', marginRight: '7px'}}/>1000人学习
                            </p>
                        </div>
                        <Divider className='line'/>
                    </div>
                    <div className='list'>
                        <div className='list_title'>【采耳行业前景】选择耳道为人生加码，让梦扬帆起航！</div>
                        <div className='list_info'>
                            <p>
                                <UnorderedListOutlined style={{fontSize: '11px', color: '#919090', marginRight: '7px'}}/>6节
                            </p>
                            <p>
                                <IconFont type='ico-user-group-off' style={{fontSize: '11px', color: '#919090', marginRight: '7px'}}/>1000人学习
                            </p>
                        </div>
                        <Divider className='line'/>
                    </div>
                    <div className='list'>
                        <div className='list_title'>【独立采耳师】在耳道，成为独立采耳师，轻松创业！</div>
                        <div className='list_info'>
                            <p>
                                <UnorderedListOutlined style={{fontSize: '11px', color: '#919090', marginRight: '7px'}}/>6节
                            </p>
                            <p>
                                <IconFont type='ico-user-group-off' style={{fontSize: '11px', color: '#919090', marginRight: '7px'}}/>1000人学习
                            </p>
                        </div>
                        <Divider className='line'/>
                    </div>
                </div>):(
                    <AtLoading status="noMore" noMoreText='暂无最新课程' />
                )}
            </div>
        )
    }

    renderNewForum(){
        const { newForumList } = this.state;
        return (
            <div className='new_message_list'>
                <TitleBox
                    className='recommend-box-title-box'
                    title={`最新论坛`}
                    url='#'/>
                {newForumList.length > 0 ? (<div className='new_list'>
                    <div className='list'>
                        <div className='list_title'>【公司宣传片】走进耳道，让我们成为一名优秀的采耳师啦啦啦萨拉斯</div>
                        <div className='list_answer'>6条回复</div>
                        <Divider className='line'/>
                    </div>
                    <div className='list'>
                        <div className='list_title'>【采耳行业前景】选择耳道为人生加码，让梦扬帆起航！</div>
                        <div className='list_answer'>6条回复</div>
                        <Divider className='line'/>
                    </div>
                </div>) : (
                    <AtLoading status="noMore" noMoreText='暂无最新论坛' />
                )}
            </div>
        )
    }

    renderNewVote(){
        const { newVoteList } = this.state;
        return (
            <div className='new_message_list'>
                <TitleBox
                    className='recommend-box-title-box'
                    title={`最新投票`}
                    url='/vote-list'/>
                {newVoteList.length > 0 ? (
                    <div className='new_list'>
                        {newVoteList.map((item,index)=>{
                            return (
                                <div className='list' key={`vote-${index}`} onClick={()=>{this.props.history.push({ pathname: `/vote/${item?.Id}`, state: item})}}>
                                    <div className='list_title'>{item?.VoteTitle}</div>
                                    <div className='list_vote'>{item?.VoteMainCount ?? 0}人已经提交</div>
                                    <Divider className='line'/>
                                </div>
                            )
                        })}
                    </div>
                ) : (<AtLoading status="noMore" noMoreText='暂无最新投票' />)}
            </div>
        )
    }

    renderNewActivity(){
        const { newActivityList } = this.state;
        return (
            <div className='new_message_list'>
                <TitleBox
                    className='recommend-box-title-box'
                    title={`最新活动`}
                    url='#'/>
                {newActivityList.length > 0 ? (<div className='new_list'>
                    <div className='list'>
                        <div className='list_title'>【项目组长投票】评选塑形项目组长带动气氛</div>
                        <div className='list_activity_info'>
                            <p>
                                <EnvironmentOutlined style={{fontSize: '11px', color: '#919090', marginRight: '7px'}}/>6节
                            </p>
                            <p>
                                <IconFont type='time' style={{fontSize: '11px', color: '#919090', marginRight: '7px'}}/>1000人学习
                            </p>
                        </div>
                        <Divider className='line'/>
                    </div>
                    <div className='list'>
                        <div className='list_title'>【优秀员工投票】2022年度优秀员工评选</div>
                        <div className='list_activity_info'>
                            <p>
                                <EnvironmentOutlined style={{fontSize: '11px', color: '#919090', marginRight: '7px'}}/>6节
                            </p>
                            <p>
                                <IconFont type='time' style={{fontSize: '11px', color: '#919090', marginRight: '7px'}}/>1000人学习
                            </p>
                        </div>
                        <Divider className='line'/>
                    </div>
                </div>) : (
                    <AtLoading status="noMore" noMoreText='暂无最新活动' />
                )}
            </div>
        )
    }

    render() {

        return (
            <div style={{paddingBottom: 56}}>
                {this.renderGirdNav()}
                {(this.props.userInfo.ErdaoHR || this.props.userInfo.Interviewer) && this.renderAudition()}
                {this.renderLessonMessage()}
                {this.renderNewForum()}
                {this.renderNewVote()}
                {this.renderNewActivity()}
                {/* <AtLoading status="noMore" /> */}
            </div>
        )
    }
}

Index = connect(mapStateToProps)(Index) || Index;
Index = entryDecorator(Index) || Index;
export default Index