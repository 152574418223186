import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Toast, Popup, Button, TextArea, Dialog } from 'antd-mobile-v5'
import { sleep } from 'antd-mobile-v5/es/utils/sleep'

import CommentList from './comment-list';
import ReleaseBox from './release-box';


import styles from './index.module.scss'

class CommentModule extends React.Component {
    state={
        inputValue: null,
        isSubmit: false,
        isReplyOn: false,
        commentItem: {},
        isShowDelete: false
    }

    getCommentItem(obj){
        // console.log(obj)
        this.setState({
            commentItem: obj
        })
    }

    // 评论
    openCommentPopup(){
        this.setState({
            isReplyOn: true
        })
    }
    closeCommentPopup(){
        this.setState({
            isReplyOn: true
        })
    }
    onChangeInput(e){
        if(!e){
            Toast.show({
                content: '内容不能为空',
                maskClickable: false,
                isSubmit: false
            })
        }
        this.setState({
            inputValue: e,
            isSubmit: true
        })
    }
    onReply(){
        const { inputValue, commentItem } = this.state;
        const { objItem, type, onReply, callBack } = this.props;
        // console.log(objItem, '文章')
        // console.log(commentItem, '评论')
        // return false;
        onReply && onReply(objItem, inputValue, type, commentItem?.Id, commentItem?.Id );
        this.setState({
            inputValue: null,
            isReplyOn: false
        },()=>{
            this.getCommentItem({});
            callBack && callBack(type);
        })
    }
    deleteComment(){
        const { commentItem } = this.state;
        const { deleteComment } = this.props;
        // console.log('delete',commentItem.Id)
        Dialog.show({
            content: '提示：评论删除后无法恢复！确认删除吗？',
            closeOnAction: true,
            actions: [
                [
                    {
                        key: 'cancel',
                        text: '取消',
                        bold: true,
                    },
                    {
                        key: 'delete',
                        text: '删除',
                        bold: true,
                        danger: true,
                        onClick:()=>{
                            deleteComment && deleteComment(commentItem?.Id);
                        }
                    },
                ]
            ],
        })
    }


    async onLike(){
        const { onLike, callBack } = this.props;
        const { commentItem } = this.state;
        await onLike && onLike(commentItem, 1, 2, commentItem?.Praise )
        await sleep(500)
        callBack && callBack(2)

    }
    
    render() {
        const { commentArry, type, onReply, callBack } = this.props;
        const { isSubmit, isReplyOn, isShowDelete } = this.state;

        return (
            <div>
                <CommentList
                    commentArry={commentArry}
                    openCommentPopup={this.openCommentPopup.bind(this)}
                    getCommentItem={this.getCommentItem.bind(this)}
                    deleteComment={this.deleteComment.bind(this)}
                    onLike={this.onLike.bind(this)}
                />
                <ReleaseBox
                    type={type}
                    openCommentPopup={this.openCommentPopup.bind(this)}
                    closeCommentPopup={this.closeCommentPopup.bind(this)}
                />

                <Popup
                    visible={isReplyOn}
                    onMaskClick={() => {
                        this.setState({isReplyOn: false})
                    }}
                    bodyStyle={{ minHeight: '18vh', borderRadius: '12px 12px 0 0', padding: '15px 10px' }}
                >
                    <div className={styles['reply-box']}>
                        <TextArea
                            className='text-area'
                            placeholder='请输入内容'
                            autoSize={{ minRows: 3 }}
                            onChange={this.onChangeInput.bind(this)}
                        />
                        <Button className='send-button' disabled={!isSubmit} onClick={()=>{this.onReply()}}>发送</Button>
                    </div>
                </Popup>

            </div>
        );
    }
}

CommentModule.defaultProps = {
    objItem: {}
};
CommentModule.propTypes = {
    onReply: PropTypes.func,
    callBack: PropTypes.func,
};

export default CommentModule
