import React from 'react'
import { connect } from 'react-redux';
import {
    Form,
    Mask,
    Tabs,
    List,
    Button,
    ActionSheet,
    Dialog,
    Picker,
    Switch,
    Toast,
    Collapse,
    Space,
    Steps,
    Loading,
    InfiniteScroll
} from 'antd-mobile-v5'
import { sleep } from 'antd-mobile-v5/es/utils/sleep'
// import entryDecorator from '../../hocs/entryDecorator'
import api from '../../utils/api'
import Fetch from '../../utils/fetch-config'
import { resetTimeType } from '../../hocs/getTime'

import './list-page.scss'

const mapStateToProps = store => ({
    userInfo: store.userInfo,
});

class AuditionList extends React.Component {
    formRef = React.createRef();
    state={
        listData: [],                  //列表
        tabKey: '1',                   // 1 全部 2 初试 3 复试
        pageIndex: 1,
        totalPage: 0,
        totalCount: 0,
        actions: [
            { text: '分配', key: 'allocated', onClick: ()=>this.showDialog() },
            { text: '面试详情', key: 'show', onClick: ()=>this.showHRAppraise() },
            { text: '简历详情', key: 'show', onClick: ()=>this.toDetailPages() },
        ],
        allocatedObj: {},              //被选中的简历数据
        visible: false,
        cityList: [],
        cityVisible: false,
        fourAnswer: '',
        dialogVisible: false,
        HRList: [],
        keyArr: [],
        AppraiseMask: false,
        interviewDetail: {}
    }
    componentWillMount() {
        this.getCityList();
        this.getMemberHRList();
        this.getResumeInfoByMemberId();
    }
    componentWillReceiveProps () {}
    componentWillUnmount () { }
    componentDidShow () { }
    componentDidHide () { }
    
    getCityList(){
        Fetch.get(api.getCityList).then(res=>{
            this.setState({
                cityList: (res.data && res.data.length > 0) ? res.data : []
            })
        })
    }
    getMemberHRList(){
        let HRList = [];
        Fetch.get(api.getMemberHRList).then(res=>{
            if(res.data && res.data.length > 0){
                [...res.data].map(item=>{
                    let itemObj = {};
                    itemObj.key = item.Name;
                    itemObj.value = item.Id;
                    itemObj.label = item.RealName ? item.RealName : item.Nick;
                    HRList.push(itemObj)
                })
                this.setState({
                    HRList
                })
            }
        })
    }

    async getResumeInfoByMemberId(){
        const { pageIndex, totalPage, totalCount, listData, tabKey } = this.state
        const paramUrl = `?MemberId=${this.props.userInfo.Id}&pageIndex=${pageIndex}&Type=${tabKey}`
        if (pageIndex === 1){
            // await sleep(500)
            Fetch.get(api.getResumeInfoByMemberId + paramUrl).then(res=>{
                this.setState({
                    listData: res.data.List,
                    pageIndex: res.data.PageIndex,
                    totalPage: res.data.TotalPage,
                    totalCount: res.data.TotalCount
                })
            })
        }else if (pageIndex < totalPage){
            await sleep(500)
            Fetch.get(api.getResumeInfoByMemberId + paramUrl).then(res=>{
                this.setState({
                    listData: listData.concat(res.data.List),
                    pageIndex: res.data.PageIndex,
                    totalPage: res.data.TotalPage,
                    totalCount: res.data.TotalCount
                })
            })
        }else if(pageIndex === totalPage){
            this.setState({
                hasMore: false
            })
        }
    }

    onChange(tabKey){
        this.setState({
            tabKey
        },async ()=>{
            await this.getResumeInfoByMemberId()
        })
    }

    toDetailPages(){
        const { allocatedObj } = this.state;
        this.props.history.push({ pathname: `/form-detailPage/${allocatedObj.Id}`, state: allocatedObj });
    }

    updateResumeInfoStatus(){
        const { allocatedObj } = this.state;
        const value = this.formRef.current?.getFieldsValue();
        const values = {
            MemberId: this.props.userInfo.Id,
            ResumeId: allocatedObj.Id,
            FourAnswer: (value['FourAnswer'] && value['FourAnswer'][0]) ?? '',
            FirstMemberId: (value['FirstMemberId'] && value['FirstMemberId'][0]) ?? '00000000-0000-0000-0000-000000000000',
            SecondMemberId: (value['SecondMemberId'] && value['SecondMemberId'][0]) ?? '00000000-0000-0000-0000-000000000000',
            CommentType: allocatedObj.ResumeStatus,
            ResumeStatus: (value['ResumeStatus'] && value['ResumeStatus']) ?? false,
            CommentContent: '',
            list: [{}],
        }

        Fetch.post(api.updateResumeInfoStatus, values).then(res=>{
            Toast.show({
                content: res.message,
                maskClickable: false,
                afterClose: ()=>{
                    this.getResumeInfoByMemberId()
                }
            })
        })
    }

    async showDialog(){
        const { allocatedObj } = this.state;
        if ((allocatedObj.ResumeStatus === 0 || allocatedObj.ResumeStatus === 4) && this.props.userInfo.ErdaoHR){
            this.setState({
                visible: false,
                dialogVisible: true
            });
        }else if ((allocatedObj.ResumeStatus !== 0 || allocatedObj.ResumeStatus !== 4) && this.props.userInfo.ErdaoHR){
            Toast.show({
                content: '已分配过',
                maskClickable: false,
            })
        }else{
            Toast.show({
                content: '您没有权限',
                maskClickable: false,
            })
        }
    }

    showHRAppraise(){
        const { allocatedObj } = this.state;
        if(this.props.userInfo.ErdaoHR || this.props.userInfo.Interviewer){
            this.setState({
                visible: false,
                AppraiseMask: true
            }, ()=>this.getResumeInterviewDetailList(allocatedObj.Id))
        }else{
            Toast.show({
                content: '您没有权限',
                maskClickable: false,
            })
        }
    }

    getHRName(HRId){
        const { HRList } = this.state;
        let HRName = ''
        HRList.forEach(item=>{
            if (item.value === HRId){
                HRName = item.label;
            }
        });
        return HRName;
    }

    getResumeInterviewDetailList(id){
        Fetch.get(api.getResumeInterviewDetailList + `?ResumeId=${id}`).then(res=>{
            console.log(res)
            this.setState({
                interviewDetail: res.data
            })
        })
    }
    getScore(val){
        switch (val) {
            case 10:
                return '特优'
                break;
            case 8:
                return '优'
                break;
            case 6:
                return '良'
                break;
            case 4:
                return '中'
                break;
            case 2:
                return '差'
                break;
            default:
                return val
                break;
        }
    }

    renderHRAppraise(){
        const { allocatedObj, resumeStatus, AppraiseMask, interviewDetail } = this.state;

        return(
            <Mask visible={AppraiseMask} onMaskClick={() => this.setState({AppraiseMask: false})}>
                <div className='HRAppraise' onClick={(e)=> e.stopPropagation()}>
                    <List>
                        <List.Item>
                            <Space>
                                <div>区域：</div>
                                <div>{allocatedObj.FourAnswer}</div>
                            </Space>
                        </List.Item>
                        <List.Item>
                            <Space>
                                <div>初试官：</div>
                                <div>{this.getHRName(allocatedObj.FirstHR)}</div>
                            </Space>
                        </List.Item>
                        <List.Item>
                            <Space>
                                <div>复试官：</div>
                                <div>{this.getHRName(allocatedObj.SecondHR)}</div>
                            </Space>
                        </List.Item>
                    </List>
                    <Collapse accordion>
                        {interviewDetail.length > 0 && interviewDetail.map(item=>{
                            return(
                                <Collapse.Panel key={item.CommentType} title={item.CommentType === 1 ? '初试面试评价' : '复试面试评价'}>
                                    <List.Item>
                                        <Space>
                                            <div>总分值：</div>
                                            <div>
                                                {item.list.length > 0 && item.list.reduce((pre,cur)=>{
                                                    return pre + cur?.DetailScore
                                                },0)}
                                            </div>
                                        </Space>
                                    </List.Item>
                                    {item?.list.length > 0 && item.list.sort((a,b)=>a?.QuestionId-b?.QuestionId).map(listItem=>{
                                        return(
                                            <List.Item key={listItem.QuestionId}>
                                                <Space>
                                                    <div>{listItem.CommentPoint + '：'}</div>
                                                    <div>{this.getScore(listItem.DetailScore) + `----${listItem.DetailScore}`}</div>
                                                </Space>
                                            </List.Item>
                                        )
                                    })}
                                    <List.Item>
                                        <div>考官评价</div>
                                        <div>{item.CommentContent ?? '暂无评价'}</div>
                                    </List.Item>
                                </Collapse.Panel>
                            )
                        })}
                    </Collapse>
                </div>
            </Mask>
        )
    }
    
    render() {
        const {
            listData,
            visible,
            actions,
            dialogVisible,
            cityList,
            cityVisible,
            HRList,
            firstVisible,
            secondVisible,
            interviewDetail,
            hasMore
        } = this.state;
        return (
            <div className='list_page'>
                <Tabs className='list_tabs' defaultActiveKey='1' onChange={(key)=>this.onChange(key)}>
                    <Tabs.TabPane title='全部' key='1' />
                    <Tabs.TabPane title='初试' key='2' />
                    <Tabs.TabPane title='复试' key='3' />
                </Tabs>
                <List>
                    { listData.length > 0 && listData.map((item, index)=>{
                        return (
                            <List.Item
                                key={item.Id}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    (this.props.userInfo.ErdaoHR || this.props.userInfo.Interviewer) && this.setState({visible: true, allocatedObj: item})
                                }}
                                description={<span style={{fontSize: '10px'}}>{resetTimeType(item.AddDate,'YY-mm-dd')}</span>}
                                arrow={
                                    <div className='stepstyle'>
                                        <Steps current={item.ResumeStatus}>
                                            <Steps.Step title='待面试'/>
                                            {item.ResumeStatus === 4 ? null : <Steps.Step title='待初试'/>}
                                            {(item.ResumeStatus === 4 || item.ResumeStatus === 5) ? null : <Steps.Step title='待复试'/>}
                                            {item.ResumeStatus === 3 && <Steps.Step title='通过'/>}
                                            {item.ResumeStatus === 4 && <Steps.Step title='人事未通过'/>}
                                            {item.ResumeStatus === 5 && <Steps.Step title='初试未通过'/>}
                                            {item.ResumeStatus === 6 && <Steps.Step title='复试未通过'/>}
                                        </Steps>
                                    </div>
                                }
                            >
                                {item.RealName}
                            </List.Item>
                        )
                    })}
                </List>
                <InfiniteScroll loadMore={()=>this.getResumeInfoByMemberId()} hasMore={hasMore}>
                    {hasMore ? (
                        <>
                            <span>加载中</span>
                            <Loading />
                        </>
                    ) : (
                        <span>--- 没有更多了 ---</span>
                    )}
                </InfiniteScroll>

                <ActionSheet
                    extra='请选择你要进行的操作'
                    cancelText='取消'
                    visible={visible}
                    actions={actions}
                    onClose={() => this.setState({visible: false})}
                />

                <Dialog
                    className='allocated_Dialog'
                    visible={dialogVisible}
                    content={
                        <Form
                            className="form_assess"
                            layout='horizontal'
                            ref={this.formRef}
                            onFinish={(data)=>{this.submitForm(data)}}
                        >
                            <Form.Item
                                name='FourAnswer'
                                label='工作地分配'
                                trigger='onConfirm'
                                onClick={() => {
                                    this.setState({
                                        cityVisible: true
                                    })
                                }}
                            >
                                <Picker
                                    columns={[cityList]}
                                    visible={cityVisible}
                                    onClose={() => {
                                        this.setState({
                                            cityVisible: false
                                        })
                                    }}
                                    onClick={e => {e.stopPropagation()}}
                                >
                                    {items => {
                                        if (items.every(item => item === null)) {
                                            return '未选择'
                                        } else {
                                            return items.map(item => item?.label ?? '未选择')
                                        }
                                    }}
                                </Picker>
                            </Form.Item>
                            <Form.Item
                                name='FirstMemberId'
                                label='初试面试官分配'
                                trigger='onConfirm'
                                onClick={() => {
                                    this.setState({
                                        firstVisible: true
                                    })
                                }}
                            >
                                <Picker
                                    columns={[HRList]}
                                    visible={firstVisible}
                                    onClose={() => {
                                        this.setState({
                                            firstVisible: false
                                        })
                                    }}
                                    onClick={e => {e.stopPropagation()}}
                                >
                                    {firstItems => {
                                        if (firstItems.every(item => item === null)) {
                                            return '未选择'
                                        } else {
                                            return firstItems.map(item => item?.label ?? '未选择')
                                        }
                                    }}
                                </Picker>
                            </Form.Item>
                            <Form.Item
                                name='SecondMemberId'
                                label='复试面试官分配'
                                trigger='onConfirm'
                                onClick={() => {
                                    this.setState({
                                        secondVisible: true
                                    })
                                }}
                            >
                                <Picker
                                    columns={[HRList]}
                                    visible={secondVisible}
                                    onClose={() => {
                                        this.setState({
                                            secondVisible: false
                                        })
                                    }}
                                    onClick={e => {e.stopPropagation()}}
                                >
                                    {SecondItems => {
                                        if (SecondItems.every(item => item === null)) {
                                            return '未选择'
                                        } else {
                                            return SecondItems.map(item => item?.label ?? '未选择')
                                        }
                                    }}
                                </Picker>
                            </Form.Item>
                            <Form.Item label='是否通过' name='ResumeStatus' >
                                <Switch style={{ '--checked-color': '#DAAE65' }} />
                            </Form.Item>
                        </Form>
                    }
                    closeOnAction
                    onClose={() => {
                        this.setState({
                            dialogVisible: false
                        })
                    }}
                    actions={[[
                        {
                            key: 'cancel',
                            text: '取消',
                        },
                        {
                            key: 'submit',
                            text: '保存',
                            onClick: ()=>{this.updateResumeInfoStatus()}
                        },
                    ]]}
                />
                {interviewDetail && this.renderHRAppraise()}
            </div>
        )
    }
}

AuditionList = connect(mapStateToProps)(AuditionList) || AuditionList;
// AuditionList = entryDecorator(AuditionList) || AuditionList;
export default AuditionList