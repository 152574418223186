import React from 'react';
import { connect } from 'react-redux';
import { FormInstance } from 'antd/es/form';
import NavTab from '../../components/navTab'
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import api, { apiUrl } from '../../utils/api';
import Fetch from '../../utils/fetch-config'
import { isEmptyObject, getAllUrlParam } from '../../utils/common'
import entryDecorator from '../../hocs/entryDecorator'
import { updateUserInfo } from '../../actions/userInfo'
import { Toast, Form, Input, Button, Radio, Space, DatePicker, TextArea, Card, ImageUploader } from 'antd-mobile-v5';
import dayjs from 'dayjs';
import { sleep } from 'antd-mobile-v5/es/utils/sleep'

// import './audition.scss'

const now = new Date();

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}


const mapStateToProps = store => ({
    userInfo: store.userInfo,
});

const mapDispatchToProps = dispatch => ({
    updateUserInfo: userInfo => {
        dispatch(updateUserInfo(userInfo));
    }
});

class Upload extends React.Component {
    formRef = React.createRef();

    state={
        userImageFile: [],
        picture: null
    }

    componentWillMount() {
        const params = getAllUrlParam(this.props.location.search.slice(1));
        // /pages/webviews/manage?router=/audition
    }
    componentWillReceiveProps () {}
    componentWillUnmount () {}
    componentDidShow () {}
    componentDidHide () {}

    //将base64转换为blob
    dataURLtoBlob(dataurl) { 
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }
    //将blob转换为file
    blobToFile(theBlob, fileName){
       theBlob.lastModifiedDate = new Date();
       theBlob.name = fileName;
       return theBlob;
    }

    async userImageUpload(file, objName){
        // console.log(file,'upload')
        const _this = this;
        let uploadUrlData = {};
        if(file.size > 1 * 1024 * 1024){
            let newFiles = {};
            await new Promise((resolve)=>{
                let r = new FileReader();  //本地预览
                r.readAsDataURL(file)
                r.onload = () => {
                    let src=r.result
                    const img = new Image();
                    img.src = src;
                    img.onload = () => {
                        const W = img .width;
                        const h = img. height;
                        const quality = 0.7; 
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext( '2d');
                        //创建属性节点
                        const anw = document.createAttribute("width");anw.nodeValue = W;
                        const anh = document.createAttribute("height");anh.nodeValue = h;
                        canvas . setAttributeNode(anw);
                        canvas. setAttributeNode(anh);
                        //底色PNG转JPEG时透明区域会变黑色
                        ctx.fillStyle="#fff";
                        ctx.fillRect(0, 0, W, h);
                        ctx.drawImage(img, 0, 0, W, h);
                        //quality値越小, 所绘制出的图像越横楜
                        const base64 = canvas.toDataURL( file.type, quality);
                        let bold = _this.dataURLtoBlob(base64);
                        resolve(newFiles = _this.blobToFile(bold, file.name))
                    }
                }
                // return newFiles
            }).then(async newFilesData => {
                let data = new FormData();
                data.append(objName, newFilesData, newFilesData.name);
                const baseUrl = process.env.NODE_ENV === 'production' ? apiUrl : '/api';
                // let uploadUrlData = {};
                await new Promise(resolve => {
                    fetch(baseUrl + api.uploadGoodsCommentFile, {
                        method: 'post',
                        body: data,
                    })
                        .then(res => res.json())
                        .then(urlData => {
                            // console.log(urlData,'>1 urlData')
                            resolve(uploadUrlData = urlData);
                        }).catch(err=>console.log(err))
                })
                // await sleep(3000)
                // console.log(uploadUrlData?.data[0].ImageUrl)
                // return {
                //     url: uploadUrlData?.data[0].ImageUrl,
                // }
            });
        }else{
            let data = new FormData();
            data.append(objName, file, file.name);
            const baseUrl = process.env.NODE_ENV === 'production' ? apiUrl : '/api';
            
            await new Promise(resolve => {
                fetch(baseUrl + api.uploadGoodsCommentFile, {
                    method: 'post',
                    body: data,
                })
                    .then(res => res.json())
                    .then(urlData => {
                        // console.log(urlData,'<1 urlData')
                        resolve(uploadUrlData = urlData);
                    }).catch(err=>console.log(err))
            })
        }
        await sleep(2000)
        return {
            url: uploadUrlData?.data[0].ImageUrl,
        }
    }

    handleChange = info => {
        console.log(info,'info')
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    imageUrl,
                    loading: false,
                })
            );
        }
    };

    renderForm(){
        const baseUrl = process.env.NODE_ENV === 'production' ? apiUrl : '/api';
        const {
            picture,
            userImageFile
        } = this.state;

        return (
            <ImageUploader
                maxCount={1}
                value={userImageFile}
                onChange={(_f)=>{
                    console.log(_f,'脸')
                    this.setState({
                        userImageFile: _f
                    })
                }}
                upload={(file)=>this.userImageUpload(file, 'userImage')}
            />
        )
    }

    render() {
        const { cityList } = this.state;
        
        return (
            <div>
                {process.env.NODE_ENV === 'production' ? null : <NavTab title="应聘登记表" onClick={()=>{this.props.history.goBack();}}/>}
                {this.renderForm()}
            </div>
        )
    }
}

Upload = connect(mapStateToProps, mapDispatchToProps)(Upload) || Upload;
Upload = entryDecorator(Upload) || Upload;
export default Upload