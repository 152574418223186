import { UPDATE_USERINFO } from '../constants/userInfo';
import { isEmptyObject } from '../utils/common';

let defaultState = localStorage.getItem('state') && JSON.parse(localStorage.getItem('state'));
const defaultUserInfo = isEmptyObject(defaultState) ? {} : (defaultState && defaultState.userInfo);
export default function userInfo(state = defaultUserInfo, action) {
    if (action.type === UPDATE_USERINFO) {
        return state = action.userInfo;
    }
    return state;
}