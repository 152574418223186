import React from 'react'
import { connect } from 'react-redux';
import {
    List,
    Form,
    Grid,
    Button,
    Input,
    Radio,
    Space,
    DatePicker,
    Toast
} from 'antd-mobile-v5'
import dayjs from 'dayjs';
import api, { apiUrl } from '../../utils/api';
import Fetch from '../../utils/fetch-config'


import './personalInformation.scss'

const mapStateToProps = store => ({
    userInfo: store.userInfo
});

const now = new Date();

class PersonalInformation extends React.Component {
    formTopRef = React.createRef();
    formCenterRef = React.createRef();
    formBottomRef = React.createRef();
    formStudyRef = React.createRef();
    formInternalStaffRef = React.createRef();

    state={
        isCaiershi: false,
        showStatus: false,
        caiershiInformation: null,
        overDateVisible: false,
    }
    componentWillMount() {
        this.existsUser();
        this.getResumeInfoDetailByMemebrId()
    }
    componentWillReceiveProps () {}
    componentWillUnmount () { }
    componentDidShow () { }
    componentDidHide () { }

    existsUser(){
        Fetch.get(api.existsUser + `?memberId=${this.props.userInfo.Id}`).then(res=>{
            this.setState({
                isCaiershi: res.data
            })
        })
    }

    getResumeInfoDetailByMemebrId(){
        Fetch.get(api.getResumeInfoDetailByMemebrId + `?MemberId=${this.props.userInfo.Id}`).then(res=>{
            console.log(res)
            this.setState({
                caiershiInformation: res.data
            })
        })
    }



    isEmpty(obj) {
        if (typeof obj === 'undefined' || obj === null) {
            return true;
        } else {
            return false;
        }
    }
    setDate(obj){
        const { userInfo } = this.props;
        const { caiershiInformation, isCaiershi } = this.state;
        Object.keys(obj).forEach(item=>{
            if(this.isEmpty(obj[item])) {
                if(isCaiershi){
                    obj[item] = caiershiInformation?.[item] ?? userInfo?.[item] ?? '';
                }else{
                    obj[item] = userInfo?.[item] ?? '';
                }
            }
        })
        return obj;
    }

    submitForm(type){
        const { userInfo } = this.props;
        const { caiershiInformation } = this.state;
        const Birthday = this.formTopRef.current?.getFieldValue('Birthday');
        const OverDate = this.formBottomRef.current?.getFieldValue('OverDate');

        if(type){
            let obj = {
                ...this.formTopRef.current.getFieldsValue(),
                ...this.formCenterRef.current.getFieldsValue(),
                ...this.formBottomRef.current.getFieldsValue(),
                Birthday: dayjs(caiershiInformation?.Birthday ?? userInfo?.Birthday).format('YYYY-MM-DD') ?? dayjs(Birthday).format('YYYY-MM-DD'),
                OverDate: (caiershiInformation?.OverDate!== '0001-01-01T00:00:00' && userInfo?.OverDate!== '0001-01-01T00:00:00') ? (dayjs(caiershiInformation?.OverDate ?? userInfo?.OverDate).format('YYYY-MM-DD')) : dayjs(OverDate).format('YYYY-MM-DD'),
                MemberId: caiershiInformation?.MemberId ?? userInfo?.Id
            }
            let newObj = this.setDate(obj);
            // console.log(newObj)

            Fetch.post(api.updateResumeInfo, newObj).then(res=>{
                if (res.code === 200){
                    Toast.show({
                        content: res.message,
                        maskClickable: false,
                        afterClose: ()=>{
                            this.props.history.goBack();
                        }
                    });
                }else{
                    Toast.show({
                        content: res.message,
                        maskClickable: false,
                    });
                }
            })
        }else{
            let obj = {
                ...this.formTopRef.current.getFieldsValue(),
                MemberId: userInfo?.Id
            }
            let newObj = this.setDate(obj)
            Fetch.post(api.updateMemberInfo, newObj).then(res=>{
                if (res.code === 200){
                    Toast.show({
                        content: res.message,
                        maskClickable: false,
                        afterClose: ()=>{
                            this.props.history.goBack();
                        }
                    });
                }else{
                    Toast.show({
                        content: res.message,
                        maskClickable: false,
                    });
                }
            })
        }
    }

    renderTopBox(){
        const { caiershiInformation, birthdayVisible } = this.state
        return (
            <Form
                className='information-box'
                layout='horizontal'
                ref={this.formTopRef}
            >
                <Form.Item
                    name='RealName'
                    label='姓名'
                >
                    {(caiershiInformation?.RealName || this.props.userInfo?.RealName) ? (
                        <p style={{textAlign: 'right'}}>{caiershiInformation?.RealName ?? this.props.userInfo?.RealName}</p>
                    ) : (
                        <Input placeholder='请输入您的姓名'/>
                    )}
                </Form.Item>
                <Form.Item
                    name='Sex'
                    label='性别'
                >
                    <p style={{textAlign: 'right'}}>{(caiershiInformation?.Sex === 1 ? '男' : '女') ?? (this.props.userInfo?.Sex === 1 ? '男' : '女')}</p>
                    {/* {(caiershiInformation?.Sex || this.props.userInfo?.Sex) ? (
                        <p style={{textAlign: 'right'}}>{(caiershiInformation?.Sex === 1 ? '男' : '女') ?? (this.props.userInfo?.Sex === 1 ? '男' : '女')}</p>
                    ) : (
                        <Radio.Group>
                            <Space>
                                <Radio value={1}>男</Radio>
                                <Radio value={2}>女</Radio>
                            </Space>
                        </Radio.Group>
                    )} */}
                </Form.Item>
                <Form.Item
                    name='Age'
                    label='年龄'
                >
                    {(caiershiInformation?.Age || this.props.userInfo?.Age) ? (
                        <p style={{textAlign: 'right'}}>{caiershiInformation?.Age ?? this.props.userInfo?.Age}</p>
                    ) : (
                        <Input type='number' min={1} max={3} pattern="[0-9]{1,3}" placeholder='请输入您的年龄'/>
                    )}
                </Form.Item>
                <Form.Item
                    name='Politics'
                    label='政治面貌'
                >
                    {(caiershiInformation?.Politics || this.props.userInfo?.Politics) ? (
                        <p style={{textAlign: 'right'}}>{caiershiInformation?.Politics ?? this.props.userInfo?.Politics}</p>
                    ) : (
                        <Input placeholder='群众，团员，党员'/>
                    )}
                </Form.Item>
                <Form.Item
                        name='Birthday'
                        label='出生日期'
                        trigger={(caiershiInformation?.Birthday || this.props.userInfo?.Birthday) ? 'onChange' : 'onConfirm'}
                        onClick={()=>{this.setState({birthdayVisible: true})}}
                    >
                        { (caiershiInformation?.Birthday || this.props.userInfo?.Birthday) ? (
                            <p style={{textAlign: 'right'}}>{dayjs(caiershiInformation?.Birthday).format('YYYY-MM-DD') ?? dayjs(this.props.userInfo?.Birthday).format('YYYY-MM-DD')}</p>
                        ) : (
                            <DatePicker
                                title='请选择出生日期'
                                visible={birthdayVisible}
                                min={new Date('1940')}
                                max={now}
                                defaultValue={now}
                                onClose={() => {this.setState({birthdayVisible: false})}}
                                onClick={e => {e.stopPropagation()}}
                            >
                                {birthdayDate =>
                                    birthdayDate ? dayjs(birthdayDate).format('YYYY-MM-DD') : '请选择您的出生日期'
                                }
                            </DatePicker>
                        )}
                    </Form.Item>
                <Form.Item
                    name='Marry'
                    label='婚姻状况'
                >
                    {(caiershiInformation?.Marry !== '' || this.props.userInfo?.Marry !== '') ? (
                        <p style={{textAlign: 'right'}}>{(caiershiInformation?.Marry === true ? '已婚' : '未婚') || (this.props.userInfo?.Marry === true ? '已婚' : '未婚')}</p>
                    ) : (
                        <Radio.Group style={{textAlign: 'right'}} >
                            <Space>
                                <Radio value={true}>已婚</Radio>
                                <Radio value={false}>未婚</Radio>
                            </Space>
                        </Radio.Group>
                    )}
                </Form.Item>
                <Form.Item
                    name='IdCard'
                    label='身份证号'
                >
                    {(caiershiInformation?.IdCard || this.props.userInfo?.IdCard) ? (
                        <p style={{textAlign: 'right'}}>{caiershiInformation?.IdCard ?? this.props.userInfo?.IdCard}</p>
                    ) : (
                        <Input placeholder='请填写您的身份证号'/>
                    )}
                </Form.Item>
                <Form.Item
                    name='Mobile'
                    label='手机号'
                >
                    {(caiershiInformation?.Mobile || this.props.userInfo?.Mobile) ? (
                        <p style={{textAlign: 'right'}}>{caiershiInformation?.Mobile ?? this.props.userInfo?.Mobile}</p>
                    ) : (
                        <Input type="tel" maxLength={11} placeholder='请填写您的手机号码'/>
                    )}
                </Form.Item>
            </Form>
        )
    }

    renderCenterBox(){
        const { caiershiInformation } = this.state;
        return (
            <Form
                className='information-box'
                layout='horizontal'
                ref={this.formCenterRef}
            >
                <Form.Item
                    name='Height'
                    label='身高(cm)'
                >
                    {(caiershiInformation?.Height || this.props.userInfo?.Height) ? (
                        <p style={{textAlign: 'right'}}>{caiershiInformation?.Height ?? this.props.userInfo?.Height}</p>
                    ) : (
                        <Input type='number' placeholder='请输入您的身高'/>
                    )}
                </Form.Item>
                <Form.Item
                    name='Weight'
                    label='体重(kg)'
                >
                    {(caiershiInformation?.Weight || this.props.userInfo?.Weight) ? (
                        <p style={{textAlign: 'right'}}>{caiershiInformation?.Weight ?? this.props.userInfo?.Weight}</p>
                    ) : (
                        <Input type='number' placeholder='请输入您的体重'/>
                    )}
                </Form.Item>
                <Form.Item
                    name='Nation'
                    label='民族'
                >
                    {(caiershiInformation?.Nation || this.props.userInfo?.Nation) ? (
                        <p style={{textAlign: 'right'}}>{caiershiInformation?.Nation ?? this.props.userInfo?.Nation}</p>
                    ) : (
                        <Input placeholder='请输入您的民族'/>
                    )}
                </Form.Item>
                <Form.Item
                    name='Native'
                    label='籍贯'
                >
                    {(caiershiInformation?.Native || this.props.userInfo?.Native) ? (
                        <p style={{textAlign: 'right'}}>{caiershiInformation?.Native ?? this.props.userInfo?.Native}</p>
                    ) : (
                        <Input placeholder='请输入您的籍贯'/>
                    )}
                </Form.Item>
                <Form.Item
                    name='Education'
                    label='学历'
                >
                    {(caiershiInformation?.Education || this.props.userInfo?.Education) ? (
                        <p style={{textAlign: 'right'}}>{caiershiInformation?.Education ?? this.props.userInfo?.Education}</p>
                    ) : (
                        <Input placeholder='请输入您的学历'/>
                    )}
                </Form.Item>
                <Form.Item
                    name='SchoolName'
                    label='毕业学校'
                >
                    {(caiershiInformation?.SchoolName || this.props.userInfo?.SchoolName) ? (
                        <p style={{textAlign: 'right'}}>{caiershiInformation?.SchoolName ?? this.props.userInfo?.SchoolName}</p>
                    ) : (
                        <Input placeholder='请输入您的毕业学校名称'/>
                    )}
                </Form.Item>
                <Form.Item
                    name='IdCardAdress'
                    label='身份证地址'
                >
                    {(caiershiInformation?.IdCardAdress || this.props.userInfo?.IdCardAdress) ? (
                        <p style={{textAlign: 'right'}}>{caiershiInformation?.IdCardAdress ?? this.props.userInfo?.IdCardAdress}</p>
                    ) : (
                        <Input placeholder='请输入您的身份证地址'/>
                    )}
                </Form.Item>
                <Form.Item
                    name='NativeAdress'
                    label='户籍所在地'
                >
                    {(caiershiInformation?.NativeAdress || this.props.userInfo?.NativeAdress) ? (
                        <p style={{textAlign: 'right'}}>{caiershiInformation?.NativeAdress ?? this.props.userInfo?.NativeAdress}</p>
                    ) : (
                        <Input placeholder='请输入您的户籍所在地'/>
                    )}
                </Form.Item>
            </Form>
        )
    }

    renderBottomBox(){
        const { overDateVisible, caiershiInformation }= this.state;
        
        return (
            <Form
                className='information-box'
                layout='horizontal'
                ref={this.formBottomRef}
            >
                <Form.Item
                    name='Recommend'
                    label='推荐人'
                >
                    {(caiershiInformation?.Recommend || this.props.userInfo?.Recommend) ? (
                        <p style={{textAlign: 'right'}}>{caiershiInformation?.Recommend ?? this.props.userInfo?.Recommend}</p>
                    ) : (
                        <Input placeholder='请输入推荐人的姓名'/>
                    )}
                </Form.Item>
                <Form.Item
                    name='UrgentName'
                    label='紧急联系人'
                >
                    {(caiershiInformation?.UrgentName || this.props.userInfo?.UrgentName) ? (
                        <p style={{textAlign: 'right'}}>{caiershiInformation?.UrgentName ?? this.props.userInfo?.UrgentName}</p>
                    ) : (
                        <Input placeholder='请输入紧急联系人姓名'/>
                    )}
                </Form.Item>
                <Form.Item
                    name='UrgentMobile'
                    label='紧急联系人手机号'
                >
                    {(caiershiInformation?.UrgentMobile || this.props.userInfo?.UrgentMobile) ? (
                        <p style={{textAlign: 'right'}}>{caiershiInformation?.UrgentMobile ?? this.props.userInfo?.UrgentMobile}</p>
                    ) : (
                        <Input type="tel" maxLength={11} placeholder='请输入紧急联系电话'/>
                    )}
                </Form.Item>
                <Form.Item
                    name='OverDate'
                    label='毕业日期'
                    trigger={(caiershiInformation?.OverDate !== '0001-01-01T00:00:00' && this.props.userInfo?.OverDate !== '0001-01-01T00:00:00') ? 'onChange' : 'onConfirm'}
                    onClick={()=>{this.setState({overDateVisible: true})}}
                >
                    {(caiershiInformation?.OverDate !== '0001-01-01T00:00:00' && this.props.userInfo?.OverDate !== '0001-01-01T00:00:00') ? (
                        <p style={{textAlign: 'right'}}>{dayjs(caiershiInformation?.OverDate).format('YYYY-MM-DD') ?? dayjs(this.props.userInfo?.OverDate).format('YYYY-MM-DD')}</p>
                    ) : (
                        <DatePicker
                            title='请选择毕业日期'
                            visible={overDateVisible}
                            min={new Date('1940')}
                            max={now}
                            defaultValue={now}
                            onClose={() => {this.setState({overDateVisible: false})}}
                            onClick={e => {e.stopPropagation()}}
                        >
                            {overDate =>
                                overDate ? dayjs(overDate).format('YYYY-MM-DD') : '请选择您的毕业日期'
                            }
                        </DatePicker>
                    )}
                </Form.Item>
            </Form>
        )
    }

    renderCaiershiInformation(){
        return(
            <>
                {this.renderTopBox()}
                {this.renderCenterBox()}
                {this.renderBottomBox()}
            </>
        )
    }

    renderInternalStaffInformation(){
        const { userInfo } = this.props;
        return (
            <Form
                className='information-box'
                layout='horizontal'
                ref={this.formInternalStaffRef}
            >
                <Form.Item
                    name='RealName'
                    label='姓名'
                >
                    {userInfo?.RealName ? (
                        <p style={{textAlign: 'right'}}>{userInfo?.RealName}</p>
                    ) : (
                        <Input placeholder='请输入您的姓名'/>
                    )}
                </Form.Item>
                <Form.Item
                    name='IdCard'
                    label='身份证号'
                >
                    {userInfo?.IdCard ? (
                        <p style={{textAlign: 'right'}}>{userInfo?.IdCard}</p>
                    ) : (
                        <Input placeholder='请填写您的身份证号'/>
                    )}
                </Form.Item>
                <Form.Item
                    name='Mobile'
                    label='手机号'
                >
                    {userInfo?.Mobile ? (
                        <p style={{textAlign: 'right'}}>{userInfo?.Mobile}</p>
                    ) : (
                        <Input type="tel" maxLength={11} placeholder='请填写您的手机号码'/>
                    )}
                </Form.Item>
                <Form.Item
                    name='UrgentName'
                    label='紧急联系人'
                >
                    {userInfo?.UrgentName ? (
                        <p style={{textAlign: 'right'}}>{userInfo?.UrgentName}</p>
                    ) : (
                        <Input placeholder='请填写紧急联系人名字'/>
                    )}
                </Form.Item>
                <Form.Item
                    name='UrgentMobile'
                    label='紧急联系人电话'
                >
                    {userInfo?.UrgentMobile ? (
                        <p style={{textAlign: 'right'}}>{userInfo?.UrgentMobile}</p>
                    ) : (
                        <Input type="tel" maxLength={11} placeholder='请填写紧急联系人的手机号码'/>
                    )}
                </Form.Item>
                <Form.Item
                    name='IdCardAdress'
                    label='身份证地址'
                >
                    {userInfo?.IdCardAdress ? (
                        <p style={{textAlign: 'right'}}>{userInfo?.IdCardAdress}</p>
                    ) : (
                        <Input placeholder='请填写您的身份证地址'/>
                    )}
                </Form.Item>
            </Form>
        )
    }
    
    render() {
        const { isCaiershi } = this.state;
        return (
            <>
                <div style={{padding: '14px 16px'}}>
                    {isCaiershi ? this.renderCaiershiInformation() : this.renderInternalStaffInformation()}
                </div>
                <Button className='submitButton' onClick={()=>this.submitForm(isCaiershi)}>确认修改</Button>
            </>
        )
    }
}

PersonalInformation = connect(mapStateToProps)(PersonalInformation) || PersonalInformation;
export default PersonalInformation