import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import Tabbar from '../components/tabbar';
import Index from '../pages/index';
import KnowledgeBase from '../pages/knowledge-base';
import DetailPage from '../pages/knowledge-base/detail-pages';
import ReleasePosts from '../pages/knowledge-base/release-posts';
import AuditionList from '../pages/audition/list-page'
import FormDetailPage from '../pages/audition/form-details'
import Audition from '../pages/audition/audition'
import Upload from '../pages/audition/upload'
import Interview from '../pages/other/interview'
import FlashSale from '../pages/other/flash-sale'
import MyCenter from '../pages/my-center';
import PersonalInformation from '../pages/my-center/personalInformation';
import Collect from '../pages/my-center/collect';
import VoteList from '../pages/vote/index';
import Vote from '../pages/vote/vote';
// import NoMatch from '../pages/not-found';


import 'antd/dist/antd.min.css';

const prodPath = '/';

class CustomRouter extends React.Component {
    render() {
        
        return (
            <Router basename={process.env.NODE_ENV === 'production' ? prodPath : '/'}>
                <Switch>
                    <ScrollToTop>
                        <Route path='/index' component={Index} />
                        <Route exact path='/knowledge-base' component={KnowledgeBase}/>
                        <Route path='/knowledge-base/detailPages/:id' component={DetailPage}/>
                        <Route path='/knowledge-base/release-posts' component={ReleasePosts}/>
                        <Route exact path='/audition-list' component={AuditionList}/>
                        <Route exact path='/form-detailPage/:id' component={FormDetailPage}/>
                        <Route exact path='/audition' component={Audition}/>
                        <Route exact path='/upload' component={Upload}/>
                        <Route exact path='/other/interview' component={Interview}/>
                        <Route exact path='/my' component={MyCenter}/>
                        <Route exact path='/my/personal-information' component={PersonalInformation}/>
                        <Route exact path='/my/collect' component={Collect}/>
                        <Route exact path='/vote-list' component={VoteList}/>
                        <Route exact path='/vote/:id' component={Vote}/>
                        <Route exact path='/other/flash-sale/:ruleId/:payOk' component={FlashSale}/>
                        {/* <Route component={NoMatch} /> */}
                        <Route exact path='/' render={(props) => <Redirect to={{pathname: '/knowledge-base', search: `${props.location.search}`,}} /> } />
                    </ScrollToTop>
                </Switch>
                <Tabbar/>
            </Router>
        )
    }
}

export default CustomRouter