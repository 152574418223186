const apiUrl = 'https://testapi.caier8.com';
// const apiUrl = 'http://192.168.1.131:8019';
// const apiUrl = 'http://47.112.206.255:8006';
// const apiUrl = 'https://api.caier8.com';

const apiInterface = {
    // Member
    getMemberById: '/Member/GetMemberById',                                                 // 获取用户信息
    // Index
    getCityList: '/Index/GetCityList',                                                      // 获取工作地
    // Web
    getDepartmentList: '/Web/GetDepartmentList',                                            // 获取部门
    getFileTypeList: '/Web/GetFileTypeList',                                                // 获取类型
    getPublishInfoListPage: '/Web/GetPublishInfoListPage',                                  // 获取文件列表
    getPublishInfoListPageV2: '/Web/GetPublishInfoListPageV2',                              // 获取文件列表V2
    createCommentPublish: '/Web/CreateCommentPublish',                                      // 创建评论
    getCommentInsideListPage: '/Web/GetCommentInsideListPage',                              // 获取评论列表
    getCommentInsideParentIdListPage: '/Web/GetCommentInsideParentIdListPage',              // 获取评论回复列表
    deleteCommentInsideById: '/Web/DeleteCommentInsideById',                                // 删除评论
    createCommentLikes: '/Web/CreateCommentLikes',                                          // 评论点赞
    createPublishInfo: '/Web/CreatePublishInfo',                                            // 新增信息发布
    createResumeInfo: '/Web/CreateResumeInfo',                                              // 提交应聘简历
    getResumeInfoByMemberId: '/Web/GetResumeInfoByMemberId',                                // 获取应聘简历列表
    createUserInterview: '/Web/CreateUserInterview',                                        // 添加采访表
    getQuestionList: '/Web/GetQuestionList',                                                // 问题列表
    updateResumeInfoStatus: '/Web/UpdateResumeInfoStatus',                                  // 修改简历状态以及提交评价
    getMemberHRList: '/Web/GetMemberHRList',                                                // 获取面试官列表
    getResumeInterviewDetailList: '/Web/GetResumeInterviewDetailList',                      // 获取面试官评价详情
    getResumeInfoDetailByMemebrId: '/Web/GetResumeInfoDetailByMemebrId',                    // 获取简历用户信息
    addUpdateFileCollectionRecord: '/Web/AddUpdateFileCollectionRecord',                    // 用户新增修改删除收藏文件夹
    getFileCollectionRecordList: '/Web/GetFileCollectionRecordList',                        // 用户获取收藏文件夹
    getMemberCollectionRecord: '/Web/GetMemberCollectionRecord',                            // 用户获取收藏文件
    updateResumeInfo: '/Web/UpdateResumeInfo',                                              // 修改采耳师信息
    getVoteMainInfoRecord: '/Web/GetVoteMainInfoRecord',                                    // 获取投票列表
    createVoteMainInfoRecord: '/Web/CreateVoteMainInfoRecord',                              // 新增投票
    // Member
    updateMemberInfo: '/Member/UpdateMemberInfo',                                           // 修改内部员工信息
    // GoodsComment
    uploadGoodsCommentFile: '/GoodsComment/UploadGoodsCommentFile',                         // 图片上传
    // UserInfo
    existsUser: '/UserInfo/ExistsUser',                                                     // 根据Id获取会员是否是在职采耳师
    // Tricket
    getSeckillRuleMainInfo: '/Tricket/GetSeckillRuleMainInfo',                              // 获取秒杀活动列表
    createSeckillRecordInfo: '/Tricket/CreateSeckillRecordInfo',                            // 创建秒杀活动支付信息
    paySeckillRecordSuccess: '/Tricket/PaySeckillRecordSuccess',                            // 秒杀活动支付


    
    
};

export default apiInterface;
export {
    apiUrl
};