import React from 'react';
import { connect } from 'react-redux';
import { Upload } from 'antd'
import { FormInstance } from 'antd/es/form';
import NavTab from '../../components/navTab'
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import api, { apiUrl } from '../../utils/api';
import Fetch from '../../utils/fetch-config'
import { isEmptyObject, getAllUrlParam } from '../../utils/common'
import entryDecorator from '../../hocs/entryDecorator'
import { updateUserInfo } from '../../actions/userInfo'
import { Toast, Form, Input, Button, Radio, Space, DatePicker, TextArea, Card, ImageUploader } from 'antd-mobile-v5';
import dayjs from 'dayjs';
import { sleep } from 'antd-mobile-v5/es/utils/sleep'

import './audition.scss'

const now = new Date();

function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}


const mapStateToProps = store => ({
    userInfo: store.userInfo,
});

const mapDispatchToProps = dispatch => ({
    updateUserInfo: userInfo => {
        dispatch(updateUserInfo(userInfo));
    }
});

class Audition extends React.Component {
    formRef = React.createRef();

    state={
        showStatus: false,
        oneAnswerOther: '',
        cityList: [],
        birthdayVisible: false,
        birthdayDate: '',
        listBeginDateVisible: false,
        listBeginDate: '',
        listEndDateVisible: false,
        listEndDate: '',
        workBeginDateVisible: false,
        workBeginDate: '',
        workEndDateVisible: false,
        workEndDate: '',
        userImageFile: [],
        justIdCardFile: [],
        backIdCardFile: [],
        loading: false,
        imageUrl: null,
    }

    componentWillMount() {
        const params = getAllUrlParam(this.props.location.search.slice(1));
        // /pages/webviews/manage?router=/audition
        this.getCityList()
    }
    componentWillReceiveProps () {}
    componentWillUnmount () {}
    componentDidShow () {}
    componentDidHide () {}

    getCityList(){
        Fetch.get(api.getCityList).then(res=>{
            this.setState({
                cityList: (res.data && res.data.length > 0) ? res.data : []
            })
        })
    }

    submitForm(formValue){
        const { oneAnswerOther } = this.state;
        const Birthday = formValue['Birthday'];
        const list = formValue['list'];
        const work = formValue['work'];

        let listData = [];
        let workData = [];
        if(list){
            let itemObj = {};
            itemObj.BeginDate = list.BeginDate ? dayjs(list.BeginDate).format('YYYY-MM') : '';
            itemObj.EndDate = list.EndDate ? dayjs(list.EndDate).format('YYYY-MM') : '';
            itemObj.InfoName = list.InfoName ? list.InfoName : '';
            itemObj.PositionName = list.PositionName ? list.PositionName : '';
            itemObj.DiplonaName = list.DiplonaName ? list.DiplonaName : '';
            itemObj.PositionType = 1;
            listData.push(itemObj)
        }else{
            let itemObj = {};
            itemObj.BeginDate = '';
            itemObj.EndDate = '';
            itemObj.InfoName = '';
            itemObj.PositionName = '';
            itemObj.DiplonaName = '';
            itemObj.PositionType = 1;
            listData.push(itemObj)
        }
        if(work){
            let itemObj = {};
            itemObj.BeginDate = dayjs(work.BeginDate).format('YYYY-MM');
            itemObj.EndDate = dayjs(work.EndDate).format('YYYY-MM');
            itemObj.InfoName = work.InfoName;
            itemObj.PositionName = work.PositionName;
            itemObj.DiplonaName = '';
            itemObj.PositionType = 2;
            workData.push(itemObj)
        }

        const values = {
            ...formValue,
            Birthday: dayjs(Birthday).format('YYYY-MM-DD'),
            SchoolName: formValue['SchoolName'] ? formValue['SchoolName'] : '',
            Major: formValue['Major'] ? formValue['Major'] : '',
            Politics: formValue['Politics'] ? formValue['Politics'] : '',
            Vision: formValue['Vision'] ? formValue['Vision'] : '',
            OneAnswer: formValue['OneAnswer'] === '其他' ? oneAnswerOther : formValue['OneAnswer'],
            FourAnswers: formValue['FourAnswers'] ? formValue['FourAnswers'] : '',
            list: listData.length > 0 ? listData : [],
            work: workData.length > 0 ? workData : [],
            MemberId: this.props.userInfo.Id,
            UserImageUrl: formValue['UserImageUrl'] ? formValue['UserImageUrl'][0].url : '',
            JustIdCardUrl: formValue['JustIdCardUrl'] ? formValue['JustIdCardUrl'][0].url : '',
            BackIdCardUrl: formValue['BackIdCardUrl'] ? formValue['BackIdCardUrl'][0].url : '',
        }
        // console.log(values)
        // return
        Fetch.post(api.createResumeInfo, values).then(res=>{
            if(res.code === 200){
                Toast.show({
                    content: res.message,
                    maskClickable: false,
                    afterClose: ()=>{
                        window.wx.miniProgram.switchTab({
                            url: `/pages/index/index`
                        });
                    }
                })
            }else{
                Toast.show({
                    content: res.message,
                    maskClickable: false
                })
            }
        })
    }

    disabledDate(current) {
        return current && current > moment().endOf('day');
    }
    getOtherAnswer(e){
        if(e.target.value.replace(/\s/g, '').length <= 0) {
            Toast.show({
                icon: 'fail',
                content: '其他了解渠道不能为空',
                maskClickable: false,
            })
        } else {
            this.setState({
                oneAnswerOther: e.target.value
            })
        }
    }

    //将base64转换为blob
    dataURLtoBlob(dataurl) { 
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }
    //将blob转换为file
    blobToFile(theBlob, fileName){
       theBlob.lastModifiedDate = new Date();
       theBlob.name = fileName;
       return theBlob;
    }

    async userImageUpload(file, objName){
        // console.log(file,'upload')
        const _this = this;
        let uploadUrlData = {};
        if(file.size > 1 * 1024 * 1024){
            let newFiles = {};
            await new Promise((resolve)=>{
                let r = new FileReader();  //本地预览
                r.readAsDataURL(file)
                r.onload = () => {
                    let src=r.result
                    const img = new Image();
                    img.src = src;
                    img.onload = () => {
                        const W = img .width;
                        const h = img. height;
                        const quality = 0.7; 
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext( '2d');
                        //创建属性节点
                        const anw = document.createAttribute("width");anw.nodeValue = W;
                        const anh = document.createAttribute("height");anh.nodeValue = h;
                        canvas . setAttributeNode(anw);
                        canvas. setAttributeNode(anh);
                        //底色PNG转JPEG时透明区域会变黑色
                        ctx.fillStyle="#fff";
                        ctx.fillRect(0, 0, W, h);
                        ctx.drawImage(img, 0, 0, W, h);
                        //quality値越小, 所绘制出的图像越横楜
                        const base64 = canvas.toDataURL( file.type, quality);
                        let bold = _this.dataURLtoBlob(base64);
                        resolve(newFiles = _this.blobToFile(bold, file.name))
                    }
                }
                // return newFiles
            }).then(async newFilesData => {
                let data = new FormData();
                data.append(objName, newFilesData, newFilesData.name);
                const baseUrl = process.env.NODE_ENV === 'production' ? apiUrl : '/api';
                // let uploadUrlData = {};
                await new Promise(resolve => {
                    fetch(baseUrl + api.uploadGoodsCommentFile, {
                        method: 'post',
                        body: data,
                    })
                        .then(res => res.json())
                        .then(urlData => {
                            // console.log(urlData,'>1 urlData')
                            resolve(uploadUrlData = urlData);
                        }).catch(err=>console.log(err))
                })
                // await sleep(3000)
                // console.log(uploadUrlData?.data[0].ImageUrl)
                // return {
                //     url: uploadUrlData?.data[0].ImageUrl,
                // }
            });
        }else{
            let data = new FormData();
            data.append(objName, file, file.name);
            const baseUrl = process.env.NODE_ENV === 'production' ? apiUrl : '/api';
            
            await new Promise(resolve => {
                fetch(baseUrl + api.uploadGoodsCommentFile, {
                    method: 'post',
                    body: data,
                })
                    .then(res => res.json())
                    .then(urlData => {
                        // console.log(urlData,'<1 urlData')
                        resolve(uploadUrlData = urlData);
                    }).catch(err=>console.log(err))
            })
        }
        await sleep(2000)
        return {
            url: uploadUrlData?.data[0].ImageUrl,
        }
    }

    handleChange = info => {
        console.log(info,'info')
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            getBase64(info.file.originFileObj, imageUrl =>
                this.setState({
                    imageUrl,
                    loading: false,
                })
            );
        }
    };

    renderForm(){
        const baseUrl = process.env.NODE_ENV === 'production' ? apiUrl : '/api';
        const {
            cityList,
            birthdayVisible,
            birthdayDate,
            listBeginDateVisible,
            listBeginDate,
            listEndDateVisible,
            listEndDate,
            workBeginDateVisible,
            workBeginDate,
            workEndDateVisible,
            workEndDate,
            userImageFile,
            justIdCardFile,
            backIdCardFile,
            loading,
            imageUrl
        } = this.state;
        const uploadButton = (
            <div>
              {loading ? <LoadingOutlined /> : <PlusOutlined />}
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );

        return (
            <div className="form_table">
                <h2 className='form_title'>应聘登记表</h2>
                <Form
                    className="audition_form"
                    ref={this.formRef}
                    layout='horizontal'
                    // initialValues={{"list": [{}], 'work': [{}]}}
                    footer={
                        <Button className='submit-btn' block type='submit' color='primary'>
                          提交
                        </Button>
                    }
                    onFinish={(values)=>this.submitForm(values)}
                >
                    <div style={{display: 'flex'}}>
                        <div>
                            <Form.Item
                                name='RealName'
                                label='姓名'
                                rules={[{ required: true, message: '姓名不能为空' }]}
                            >
                                <Input placeholder='请输入姓名' />
                            </Form.Item>
                            <Form.Item
                                name="Age"
                                label="年龄"
                                rules={[
                                    {
                                        required: true,
                                        message: '年龄不能为空',
                                    },
                                ]}
                            >
                                <Input type="number" min={1} max={3} pattern="[0-9]{1,3}" placeholder="请输入你的年龄"/>
                            </Form.Item>
                        </div>
                        <div className='userImageBox'>
                            <Form.Item
                                name='UserImageUrl'
                                rules={[{ required: true, message: '请上传你的脸部照片' }]}
                            >
                                {/* <div
                                    style={{
                                        width: '80px',
                                        height: '80px',
                                        borderRadius: '15px',
                                        background: '#b6b6b6',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                    onClick={()=>this.props.history.push({ pathname: `upload`})}
                                >
                                    <span style={{
                                        transform: 'scale(2)'
                                    }}>+</span>
                                </div> */}
                                {/* <Upload
                                    name="avatar"
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    // action="//localhost:3000/api/GoodsComment/UploadGoodsCommentFile"
                                    action={baseUrl + api.uploadGoodsCommentFile}
                                    onChange={this.handleChange}
                                >
                                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                                </Upload> */}
                                <ImageUploader
                                    maxCount={1}
                                    value={userImageFile}
                                    onChange={(_f)=>{
                                        console.log(_f,'脸')
                                        this.setState({
                                            userImageFile: _f
                                        })
                                    }}
                                    upload={(file)=>this.userImageUpload(file, 'userImage')}
                                />
                            </Form.Item>
                        </div>
                    </div>
                    <Form.Item
                        name="Sex"
                        label="性别"
                        rules={[
                            {
                                required: true,
                                message: '请选择性别',
                            },
                        ]}
                    >
                        <Radio.Group>
                            <Space>
                                <Radio value={1}>男</Radio>
                                <Radio value={2}>女</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name="Marry"
                        label="婚否"
                        rules={[
                            {
                                required: true,
                                message: '请选择婚姻状况',
                            },
                        ]}
                    >
                        <Radio.Group>
                            <Space>
                                <Radio value={0}>未婚</Radio>
                                <Radio value={1}>已婚</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name='Nation'
                        label='民族'
                        rules={[{ required: true, message: '民族不能为空' }]}
                    >
                        <Input placeholder='请输入您的所属民族' />
                    </Form.Item>
                    <Form.Item
                        name='Native'
                        label='籍贯'
                        rules={[{ required: true, message: '籍贯不能为空' }]}
                    >
                        <Input placeholder='请输入您的籍贯所在地' />
                    </Form.Item>
                    <Form.Item
                        name='Birthday'
                        label='出生日期'
                        trigger='onConfirm'
                        rules={[{ type: 'object', required: true, message: '出生日期不能为空' }]}
                        onClick={()=>{this.setState({birthdayVisible: true})}}
                    >
                        <DatePicker
                            title='请选择出生日期'
                            visible={birthdayVisible}
                            min={new Date('1940')}
                            max={now}
                            defaultValue={now}
                            onClose={() => {this.setState({birthdayVisible: false})}}
                            onClick={e => {e.stopPropagation()}}
                        >
                            {birthdayDate =>
                                birthdayDate ? dayjs(birthdayDate).format('YYYY-MM-DD') : '请选择出生日期'
                            }
                        </DatePicker>
                    </Form.Item>
                    <Form.Item
                        name='Education'
                        label='学历'
                        rules={[{ required: true, message: '学历不能为空' }]}
                    >
                        <Input placeholder='请输入您的学历' />
                    </Form.Item>
                    <Form.Item
                        name='SchoolName'
                        label='毕业学校'
                    >
                        <Input placeholder='请输入毕业学校名称' />
                    </Form.Item>
                    <Form.Item
                        name='Major'
                        label='专业'
                    >
                        <Input placeholder='请输入所学专业名称' />
                    </Form.Item>
                    <Form.Item
                        name='Politics'
                        label='政治面貌'
                    >
                        <Input placeholder='群众、团员、党员' />
                    </Form.Item>
                    <Form.Item
                        name='Mobile'
                        label='联系方式'
                        rules={[{ required: true, message: '联系方式不能为空' }]}
                    >
                        <Input type="tel" placeholder='请输入您的手机号' maxLength={11} />
                    </Form.Item>
                    <Form.Item
                        name='Height'
                        label='身高(cm)'
                        rules={[{ required: true, message: '身高不能为空' }]}
                    >
                        <Input type="number" placeholder='请输入您的身高' />
                    </Form.Item>
                    <Form.Item
                        name='Weight'
                        label='体重(kg)'
                        rules={[{ required: true, message: '体重不能为空' }]}
                    >
                        <Input type="number" placeholder='请输入您的体重' />
                    </Form.Item>
                    <Form.Item
                        name='Vision'
                        label='视力'
                    >
                        <Input type="number" placeholder='请输入您的视力' />
                    </Form.Item>
                    <Form.Item
                        name='IdCard'
                        label='身份证号码'
                        rules={[{ required: true, message: '身份证号码不能为空' }]}
                    >
                        <Input placeholder='请输入您的身份证号码' />
                    </Form.Item>
                    <Form.Item
                        name='IdCardAdress'
                        label='身份证地址'
                        rules={[{ required: true, message: '身份证地址不能为空' }]}
                    >
                        <Input placeholder='请输入您的身份证地址' />
                    </Form.Item>
                    <Form.Item
                        name='NativeAdress'
                        label='籍贯详细地址'
                        rules={[{ required: true, message: '籍贯详细地址不能为空' }]}
                    >
                        <TextArea placeholder='请输入您的籍贯详细地址' maxLength={100} autoSize={{ minRows: 3, maxRows: 5 }} />
                    </Form.Item>
                    <Form.Item
                        name='list'
                        label='教育经历'
                    >
                        <Form.Item name={['list', 'BeginDate']} trigger='onConfirm' onClick={()=>{this.setState({listBeginDateVisible: true})}}>
                            <DatePicker
                                title='开始日期'
                                visible={listBeginDateVisible}
                                min={new Date('1940')}
                                max={now}
                                defaultValue={now}
                                precision='month'
                                onClose={() => {this.setState({listBeginDateVisible: false})}}
                                onClick={e => {e.stopPropagation()}}
                            >
                                {listBeginDate =>
                                    listBeginDate ? dayjs(listBeginDate).format('YYYY-MM') : '开始日期'
                                }
                            </DatePicker>
                        </Form.Item>
                        <Form.Item name={['list', 'EndDate']} trigger='onConfirm' onClick={()=>{this.setState({listEndDateVisible: true})}}>
                            <DatePicker
                                title='结束日期'
                                visible={listEndDateVisible}
                                min={new Date('1940')}
                                max={now}
                                defaultValue={now}
                                precision='month'
                                onClose={() => {this.setState({listEndDateVisible: false})}}
                                onClick={e => {e.stopPropagation()}}
                            >
                                {listEndDate =>
                                    listEndDate ? dayjs(listEndDate).format('YYYY-MM') : '结束日期'
                                }
                            </DatePicker>
                        </Form.Item>
                        <Form.Item name={['list', 'InfoName']}>
                            <Input placeholder='学校(培训机构)名称' />
                        </Form.Item>
                        <Form.Item name={['list', 'PositionName']}>
                            <Input placeholder='专业' />
                        </Form.Item>
                        <Form.Item name={['list', 'DiplonaName']}>
                            <TextArea placeholder='所获证书' maxLength={100} autoSize={{ minRows: 3, maxRows: 5 }} />
                            
                        </Form.Item>
                    </Form.Item>
                    <Form.Item
                        name='work'
                        label='工作经历'
                        rules={[{ required: true, message: '工作经历不能为空' }]}
                    >
                        <Form.Item name={['work', 'BeginDate']} trigger='onConfirm' onClick={()=>{this.setState({workBeginDateVisible: true})}} rules={[{ required: true, message: '开始日期不能为空' }]}>
                            <DatePicker
                                title='开始日期'
                                visible={workBeginDateVisible}
                                min={new Date('1940')}
                                max={now}
                                defaultValue={now}
                                precision='month'
                                onClose={() => {this.setState({workBeginDateVisible: false})}}
                                onClick={e => {e.stopPropagation()}}
                            >
                                {workBeginDate =>
                                    workBeginDate ? dayjs(workBeginDate).format('YYYY-MM') : '开始日期'
                                }
                            </DatePicker>
                        </Form.Item>
                        <Form.Item name={['work', 'EndDate']} trigger='onConfirm' onClick={()=>{this.setState({workEndDateVisible: true})}} rules={[{ required: true, message: '结束日期不能为空' }]}>
                            <DatePicker
                                title='结束日期'
                                visible={workEndDateVisible}
                                min={new Date('1940')}
                                max={now}
                                defaultValue={now}
                                precision='month'
                                onClose={() => {this.setState({workEndDateVisible: false})}}
                                onClick={e => {e.stopPropagation()}}
                            >
                                {workEndDate =>
                                    workEndDate ? dayjs(workEndDate).format('YYYY-MM') : '结束日期'
                                }
                            </DatePicker>
                        </Form.Item>
                        <Form.Item name={['work', 'InfoName']} rules={[{ required: true, message: '开始日期不能为空' }]}>
                            <Input placeholder='工作单位名称' />
                        </Form.Item>
                        <Form.Item name={['work', 'PositionName']} rules={[{ required: true, message: '开始日期不能为空' }]}>
                            <Input placeholder='职务' />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item
                        name='OneAnswer'
                        label='1、请问您是通过哪种渠道了解到我们公司的?'
                        rules={[
                            {
                                required: true,
                                message: '请选择了解渠道'
                            }
                        ]}
                    >
                        <Radio.Group>
                            <Space direction='vertical'>
                                <Radio value="网络招聘渠道">网络招聘渠道</Radio>
                                <Radio value="他人介绍">他人介绍</Radio>
                                <Radio value="人才市场">人才市场</Radio>
                                <Radio value="门店招聘信息">门店招聘信息</Radio>
                                <Radio value="其他">
                                    <Form.Item label='其他' noStyle>
                                        <Input className='otherAnswer' placeholder="请说明其他了解途径" onChange={this.getOtherAnswer}/>
                                    </Form.Item>
                                </Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name='TwoAnswer'
                        label='2、您现在或之前的真实月工资?'
                        rules={[
                            {
                                required: true,
                                message: '请选择真实月工资'
                            }
                        ]}
                    >
                        <Radio.Group>
                            <Space direction='vertical'>
                                <Radio value="3000元左右">3000元左右</Radio>
                                <Radio value="3000-5000元">3000-5000元</Radio>
                                <Radio value="5000-8000元">5000-8000元</Radio>
                                <Radio value="8000元以上">8000元以上</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name='ThreeAnswer'
                        label='3、您在新环境中的月工资期许是?'
                        rules={[
                            {
                                required: true,
                                message: '请选择期许的月工资'
                            }
                        ]}
                    >
                        <Radio.Group>
                            <Space direction='vertical'>
                                <Radio value="3000元左右">3000元左右</Radio>
                                <Radio value="3000-5000元">3000-5000元</Radio>
                                <Radio value="5000-8000元">5000-8000元</Radio>
                                <Radio value="8000元以上">8000元以上</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name='FourAnswer'
                        label='4、您首选的工作地址是?'
                        rules={[
                            {
                                required: true,
                                message: '请选择首选的工作地点'
                            }
                        ]}
                    >
                        <Radio.Group>
                            <Space
                                wrap
                                // direction='vertical'
                            >
                                {
                                    cityList && cityList.length > 0 && cityList.map((item,index)=>{
                                        return (
                                            <Radio key={index} value={item}>{item}</Radio>
                                        )
                                    })
                                }
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name='FourAnswers'
                        label='5、您次选的工作地址是?'
                    >
                        <Radio.Group>
                            <Space
                                wrap
                                // direction='vertical'
                            >
                                {
                                    cityList && cityList.length > 0 && cityList.map((item,index)=>{
                                        return (
                                            <Radio key={index} value={item}>{item}</Radio>
                                        )
                                    })
                                }
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item
                        name='JustIdCardUrl'
                        label='身份证正面照'
                        rules={[{ required: true, message: '请上传你的身份证正面照' }]}
                    >
                        <ImageUploader
                            maxCount={1}
                            value={justIdCardFile}
                            onChange={(_f)=>{
                                console.log('正')
                                this.setState({
                                    justIdCardFile: _f
                                })
                            }}
                            upload={(file)=>this.userImageUpload(file, 'justIdCardImage')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='BackIdCardUrl'
                        label='身份证反面照'
                        rules={[{ required: true, message: '请上传你的身份证反面照' }]}
                    >
                        <ImageUploader
                            maxCount={1}
                            value={backIdCardFile}
                            onChange={(_f)=>{
                                console.log('反')
                                this.setState({
                                    backIdCardFile: _f
                                })
                            }}
                            upload={(file)=>this.userImageUpload(file, 'backIdCardImage')}
                        />
                    </Form.Item>
                    <Form.Item
                        name='Recommend'
                        label='推荐人'
                        rules={[{ required: true, message: '推荐人不能为空' }]}
                    >
                        <Input placeholder='请输入推荐人姓名' />
                    </Form.Item>
                </Form>
            </div>
        )
    }

    render() {
        const { cityList } = this.state;
        
        return (
            <div>
                {process.env.NODE_ENV === 'production' ? null : <NavTab title="应聘登记表" onClick={()=>{this.props.history.goBack();}}/>}
                {this.renderForm()}
            </div>
        )
    }
}

Audition = connect(mapStateToProps, mapDispatchToProps)(Audition) || Audition;
Audition = entryDecorator(Audition) || Audition;
export default Audition