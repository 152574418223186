import React from 'react';
import { connect } from 'react-redux';
import NavTab from '../../components/navTab';
import { List, Comment, message, Popconfirm } from 'antd'
import { TextareaItem, Popover, Icon  } from 'antd-mobile'
import { Dialog, Input, Grid, Button, Toast, Popup } from 'antd-mobile-v5'
import { CaretDownOutlined, CaretUpOutlined, createFromIconfontCN } from '@ant-design/icons'
import { AddOutline } from 'antd-mobile-icons'
import { resetTimeType } from '../../hocs/getTime'
import AtLoading from '../../components/load-more';
import classNames from 'classnames';
import api from '../../utils/api'
import Fetch from '../../utils/fetch-config'

import './detail-pages.scss'
import apiInterface from '../../utils/api';

import iconUnlike from '../../assets/images/comment/givealike.png';
import iconLike from '../../assets/images/comment/thumbup.png';
import iconReply from '../../assets/images/comment/reply.png';
import iconCollection from '../../assets/images/comment/collection.png';
import iconAlreadyCollection from '../../assets/images/comment/alreadyCollected.png';
import iconCUnlike from '../../assets/images/comment/comment-unlike.png';
import iconCLike from '../../assets/images/comment/comment-like.png';
import iconCReply from '../../assets/images/comment/comment-reply.png';


const Item = Popover.Item;

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_2736796_d2iugvz6o0c.js',
});

const mapStateToProps = store => ({
    userInfo: store.userInfo
});

class DetailPage extends React.Component {
    state={
        detailContent: {},
        contentInfo: '',
        commentValue: null,
        likeCount: 0,
        CommentList: [],
        commentCount: 0,
        startCount: 0,
        sortVisible: false,
        selectedId: 1,
        sortSelected: '按时间正序',
        isLike: false,
        isStart: false,
        isFocus: false,
        hasError: false,
        commentDataList: [],
        parentCommentId: -1,
        PageIndex: 1,
        childPageIndex: 1,
        fileList: [],
        isShow: false,
        fileName: null,
        isShowCollectFile: false,
        fileId: null
    }
    componentWillMount() {
        let oldStr = this.props.history.location.state.InfoContent;
        let newStr = oldStr.replace(/<a[^>]+>/g,(a)=>{
            if(!/\starget\s*=/.test(a)){
                return a.replace(/^<a\s/,"<a className='files' ");
            }
            return a;
        });
        this.setState({
            contentInfo: newStr,
            detailContent: this.props.history.location.state,
            isLike: this.props.history.location.state.Praise,
            isStart: this.props.history.location.state.Collect,
            likeCount: this.props.history.location.state.PraiseCount,
            startCount: this.props.history.location.state.CollectCount,
        },()=>{
            this.getCommentInsideListPage()
        })
        // console.log(process.env.NODE_ENV)
    }
    componentWillReceiveProps () {}
    componentWillUnmount () { }
    componentDidShow () {}
    componentDidHide () { }
    
    async getCommentInsideListPage(){
        const { detailContent, selectedId, PageIndex } = this.state;
        await Fetch.post(api.getCommentInsideListPage, {
            MemberId: this.props.userInfo.Id,
            PublishId: detailContent.Id,
            PageIndex: PageIndex,
            CommentStatus: 1,
            SortNum: selectedId,
        }).then(res => {
            this.setState({
                CommentList: res.data ? res.data.List : [],
                commentCount: res.data ? res.data.TotalCount : 0,
                PageIndex: res.data.PageIndex
            },()=>{
                this.arrangeCommentList()
            })
        })
    }

    getCommentInsideParentIdListPage(item, event){
        const { childPageIndex } = this.state;
        Fetch.post(api.getCommentInsideParentIdListPage, {
            CommentParentId: item.Id,
            PageIndex: childPageIndex,
            CommentStatus: 1,
        }).then(res=>{
            res.data?.List.map(listitem=>{
                let div = document.createElement('div');
                div.classList.add('childlist');
                div.innerHTML=`<span class="username">${listitem.NickName}：</span><span class='item-comment'>${listitem.CommentDesc}</span>`;
                event.target.parentNode.insertBefore(div, event.target);
                if(event.target.parentNode.childNodes.length-1 >= item.list[0].TotalCount){
                    event.target.style.display='none';
                }else{
                    this.setState({
                        childPageIndex: childPageIndex+1
                    })
                }
            })
        });
    }

    onClick(){
        this.props.history.goBack();
    }

    onReleaseComment = (value) => {
        this.setState({
            commentValue: value,
        });
    }
    
    handleSortVisibleChange = (visible) => {
        this.setState({
            sortVisible: visible,
        });
    };
    onSortSelect = (opt) => {
        let selected = '';
        switch(opt.props.value){
            case '1':
                selected='按时间正序';
                break; 
            case '2':
                selected='按时间倒序';
                break; 
            default:
                selected='按点赞倒序';
                break; 
        }
        this.setState({
            sortVisible: false,
            sortSelected: selected,
            selectedId: opt.props.value,
        },()=>{
            this.getCommentInsideListPage()
        });
    };

    async deleteCommentInsideById(id) {
        return await new Promise(resolve => {
            Fetch.post(api.deleteCommentInsideById, {
                MemberId: this.props.userInfo.Id,
                CommentId: id
            }).then((res) => {
                Toast.show({
                    content: res.message,
                    maskClickable: false,
                });
                this.getCommentInsideListPage();
                setTimeout(()=>{
                    resolve()
                },0)
            });
        });
    }

    childReply(id){
        this.setState({
            isFocus: true,
            commentValue: null,
            parentCommentId: id
        },()=>{
            this.customFocusInst.focus();
        })
    }

    arrangeCommentList(){
        const { CommentList } = this.state;
        let childCount = 0;

        let commentDataList = CommentList.length > 0 && CommentList.map((item,index)=>{
            return {
                actions: [
                    <div className='reply-box' key={`reply-${item.Id}`}>
                        <div className='comment-time'>{resetTimeType(item.AddDate)}</div>
                        <div className='comment-reply'>
                            <div onClick={()=>this.halderLike(item.Id, 1, 2, item.Praise)}>
                                <img className='icon' src={!item.Praise ? iconCUnlike : iconCLike} />
                                点赞
                            </div>
                            <div onClick={()=>{this.childReply(item.Id)}}>
                                <img className='icon' src={iconCReply} />
                                回复
                            </div>
                        </div>
                    </div>
                ],
                author: [
                    <div className='list-top' key={`list-${item.Id}`}>
                        <p className='username'>{item.NickName}</p>
                        <Popconfirm
                            arrowPointAtCenter
                            placement="bottomRight"
                            title='删除后无法恢复，确定要删除该评论吗?'
                            onConfirm={()=>this.deleteCommentInsideById(item.Id)}
                            okText="确定"
                            cancelText="取消"
                        >
                            <Icon type="ellipsis" />
                        </Popconfirm>
                    </div>
                ],
                avatar: item.AvatarUrl,
                content: (
                    <div>
                        <p className='parentComment'>{item.CommentDesc}</p>
                        {item.list.length > 0 && (
                            <div className='childComment' key={`childlist-${item.Id}`}>
                                {item.list.length > 0 && item.list.map(listItem=>{
                                    childCount = listItem.TotalCount;
                                    return (
                                        <div className='childlist' key={listItem.Id}>
                                            <span className="username">{listItem.NickName}：</span>
                                            <span className='item-comment'>{listItem.CommentDesc}</span>
                                        </div>
                                    )
                                })}
                                {(item.list.length >= 2 && childCount-item.list.length > 0) ? (
                                    <div className='more' style={{padding: '5px 0 0 0'}} onClick={this.getCommentInsideParentIdListPage.bind(this,item)}>查看全部({childCount})条回复</div>
                                ):null}
                            </div>
                        )}
                    </div>
                ),
            }
        })
        this.setState({
            commentDataList
        })
    }

    renderUserComment(){
        const { commentCount, commentDataList, sortVisible, sortSelected } = this.state;
        
        return(
            <div className='user-comment'>
                <div className='comment-header'>
                    <p>大家评价({commentCount})条</p>
                    <div>
                        <Popover mask
                            overlayClassName="sorttest"
                            overlayStyle={{ color: 'currentColor' }}
                            visible={sortVisible}
                            overlay={[
                                (<Item key="1" value="1" data-seed="logId">按时间正序</Item>),
                                (<Item key="2" value="2"  style={{ whiteSpace: 'nowrap' }}>按时间倒序</Item>),
                                (<Item key="3" value="3" > <span style={{ marginRight: 5 }}>按点赞倒序</span></Item>),
                            ]}
                            align={{
                            overflow: { adjustY: 0, adjustX: 0 },
                            offset: [-10, 0],
                            }}
                            onVisibleChange={this.handleSortVisibleChange}
                            onSelect={this.onSortSelect}
                        >
                            <div style={{
                                height: '100%',
                                padding: '0 15px',
                                marginRight: '-15px',
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                {sortSelected}
                                {sortVisible ? (<CaretUpOutlined />) : (<CaretDownOutlined />)}
                            </div>
                        </Popover>
                    </div>
                </div>
                <div className='comment-box'>
                    {commentDataList.length > 0 ? (
                        <List
                            className="comment-list"
                            itemLayout="horizontal"
                            dataSource={commentDataList}
                            renderItem={(item, index) => (
                                <li>
                                    <Comment
                                        key={index}
                                        actions={item.actions}
                                        author={item.author}
                                        avatar={item.avatar}
                                        content={item.content}
                                    />
                                </li>
                            )}
                        />
                    ): (
                        <AtLoading status="noMore" noMoreText='暂无评论' />
                    )}
                    {(commentDataList.length < commentCount) ? (<div className='more' onClick={()=>{}}>查看全部({commentCount})条评论</div>) : null}
                </div>
            </div>
        )
    }

    halderLike(id, status, type, opType, fileId){
        Fetch.post(api.createCommentLikes,{
            ArticleId: id, // 文章id
            MemberId: this.props.userInfo.Id,
            Status: status, // 1点赞 2收藏 3查看
            Type: type, // 1文章 2评论 3其他
            OperationType: !opType ? 1 : 2, // 1新增 2修改
            FileCollectionId: fileId ?? 0   // 收藏文件夹id
        }).then(res=>{
            // console.log(res,'res')
            if(type === 1 && status === 1){
                if(!opType){
                    this.setState({
                        isLike: !opType,
                        likeCount: this.state.likeCount + 1
                    })
                }else if(this.state.isLike){
                    this.setState({
                        isLike: !opType,
                        likeCount: this.state.likeCount - 1
                    })
                }
            } else if(type === 1 && status ===2){
                if(!opType){
                    this.setState({
                        isStart: !opType,
                        startCount: this.state.startCount + 1
                    })
                }else if(this.state.isStart){
                    this.setState({
                        isStart: !opType,
                        startCount: this.state.startCount - 1
                    })
                }
            } else if(type === 2){
                this.getCommentInsideListPage()
            }

        })
    }
    halderCheck(){
        console.log('点击了评论')
    }

    async sendCommon(){
        const { detailContent, commentValue, parentCommentId } = this.state;
        if (commentValue.replace(/\s/g, '').length <= 0){
            this.setState({
                hasError: true
            })
        }else{
            this.setState({
                hasError: false
            });
            await Fetch.post(api.createCommentPublish,{
                MemberId: this.props.userInfo.Id,
                CommentDesc: commentValue,
                ArticleId: detailContent.Id,
                Type: parentCommentId === -1 ? 1 : 2,
                ParentCommentId: parentCommentId,
                CommentStatus: 1,
            }).then((res)=>{
                Toast.show({
                    content: res.message,
                    maskClickable: false,
                    afterClose: ()=>{
                        this.getCommentInsideListPage();
                        if(res.message === '评论成功' || res.message === '回复成功'){
                            this.setState({
                                commentValue: null,
                                isFocus: false,
                                parentCommentId: -1
                            })
                        }
                    }
                });
            })
        }
    }
 
    onErrorClick(){
        Toast.show({
            content: '评论不能为空',
            maskClickable: false,
        });
    }

    renderReleaseBox(){
        const { detailContent, commentCount, likeCount, startCount, commentValue, isLike, isStart, isFocus } = this.state;

        return(
            <div className='release-comment-box'>
                <div className={classNames('comment-box-left', {'input-focus': isFocus})}>
                    <div
                        className={
                            classNames('box-item')
                        }
                        onClick={()=>this.halderLike(detailContent.Id, 1, 1, isLike)}
                    >
                        <img src={isLike ? iconLike : iconUnlike} className='iconStyle' />
                        <div className={classNames('font-style',{'likeCheckStyle': isLike})}>{likeCount}</div>
                    </div>
                    <div 
                        className={
                            classNames('box-item')
                        }
                        onClick={this.halderCheck.bind(this)}
                    >
                        <img src={iconReply} className='iconStyle' /> 
                        <div className='font-style'>{commentCount}</div>
                    </div>
                    <div
                        className={
                            classNames('box-item')
                        }
                        // onClick={()=>this.halderLike(detailContent.Id, 2, 1, isStart)}
                        onClick={()=>{
                            if (!isStart){
                                this.getFileCollectionRecordList()
                                this.setState({
                                    isShowCollectFile: true
                                })
                            }else{
                                this.collectFiles();
                                this.setState({
                                    isShowCollectFile: false
                                })
                            }
                        }}
                    >
                        <img src={isStart ? iconAlreadyCollection : iconCollection} className='iconStyle' />
                        <div className={classNames('font-style',{'startCheckStyle': isStart})}>{startCount}</div>
                    </div>
                </div>
                <div className='comment-box-right' onClick={e=>e.stopPropagation()}>
                    <TextareaItem
                        type="text"
                        autoHeight
                        placeholder='赶紧说些什么...'
                        onChange={this.onReleaseComment}
                        value={commentValue}
                        ref={el => this.customFocusInst = el}
                        onFocus={()=>{
                            this.setState({
                                isFocus: true
                            })
                        }}
                        onBlur={()=>{
                            if(!commentValue)this.setState({isFocus: false, parentCommentId: -1});
                        }}
                        error={this.state.hasError}
                        onErrorClick={this.onErrorClick}
                    />
                    <Button className={classNames('sendBtn', {'isShow': isFocus})} onClick={()=>{this.sendCommon();this.setState({isFocus: false})}}>发送</Button>
                </div>
            </div>
        )
    }

    addClick(){
        // console.log(this.props.location.pathname,1111)
        setTimeout(()=>{
            var aArr = document.querySelectorAll('.html_content a');
            var tagArr = Array.from(aArr)
            tagArr.length > 0 && tagArr.map(item=>{
                item.addEventListener('click',(e)=>{
                    e.preventDefault();
                    window.wx.miniProgram.navigateTo({
                        url: `/pages/webviews/file_detail?FilePath=${e.target.href}&pathname=${this.props.location.pathname}&isDownload=${this.state.detailContent.Download}`
                    });
                })
            })
        },0);
    }

    async getFileCollectionRecordList(){
        await Fetch.get(api.getFileCollectionRecordList + `?MemberId=${this.props.userInfo?.Id}`).then(res=>{
            this.setState({
                fileList: res.data
            })
        })
    }

    creatCollectFiles(id, fileType, fileStatus){
        const { fileName } = this.state;
        Fetch.post(api.addUpdateFileCollectionRecord, {
            Id: id ?? 0,
            FileName: fileName ?? '',
            MemberId: this.props.userInfo.Id ?? "00000000-0000-0000-0000-000000000000",
            FileType: fileType ?? 1,
            FileStatus: fileStatus ?? 1
        }).then(res=>{
            Toast.show({
                content: res.message,
                maskClickable: false,
                afterClose: ()=>{
                    this.getFileCollectionRecordList();
                }
            });
        })
    }

    changeInput(e){
        this.setState({
            fileName: e
        })
    }

    collectFiles(fileId){
        const {detailContent, isStart, startCount} = this.state;
        Fetch.post(api.createCommentLikes,{
            ArticleId: detailContent.Id, // 文章id
            MemberId: this.props.userInfo.Id,
            Status: 2, // 1点赞 2收藏 3查看
            Type: 1, // 1文章 2评论 3其他
            OperationType: !isStart ? 1 : 2, // 1新增 2修改
            FileCollectionId: fileId ?? 0   // 收藏文件夹id
        }).then(res=>{
            Toast.show({
                content: res.message,
                maskClickable: false,
            })
            this.setState({
                isShowCollectFile: false,
                isStart: !isStart,
                startCount: !isStart ? startCount+1 : startCount-1
            })
        })
    }

    render() {
        const { detailContent, isShow, isShowCollectFile, fileId, fileList } = this.state;

        return (
            <div>
                {process.env.NODE_ENV === 'production' ? null : <NavTab onClick={this.onClick.bind(this)}/>}
                <div className='html_content' style={{backgroundColor: '#ffffff', width: '100%', padding: '0px 10px'}} dangerouslySetInnerHTML={{__html: detailContent.InfoContent}}></div>
                {this.addClick()}
                {this.renderUserComment()}
                {this.renderReleaseBox()}
                <div className='collect-box'>
                    <Popup
                        visible={isShowCollectFile}
                        onMaskClick={() => {
                            this.setState({isShowCollectFile: false})
                        }}
                        bodyStyle={{ minHeight: '40vh', borderRadius: '16px 16px 0 0' }}
                    >
                        <div className='collectFilePopup'>
                            <div className='collectFilePopupTitle'>收藏类型</div>
                            <Grid className='collectFilePopupBox' columns={1} gap={10}>
                                {fileList.map(item=>{
                                    return (
                                        <Grid.Item key={item.Id} value={item.Id}>
                                            <Button className='collectFileItem' onClick={()=>this.collectFiles(item.Id)}>{item.FileName}</Button>
                                        </Grid.Item>
                                    )
                                })}
                            </Grid>
                            <Button
                                className='creatFiles'
                                onClick={()=>this.setState({isShow: true})}
                            >
                                <AddOutline fontSize={12}/> 创建收藏夹
                            </Button>
                        </div>
                    </Popup>

                    <Dialog
                        visible={isShow}
                        content={
                            <Input placeholder='请输入收藏夹名称' onChange={(e)=>this.changeInput(e)} />
                        }
                        closeOnAction
                        closeOnMaskClick
                        onClose={() => {
                            this.setState({isShow: false})
                        }}
                        actions={[
                            {
                                key: 'creat',
                                text: '创建',
                                onClick: ()=>this.creatCollectFiles()
                            },
                        ]}
                    />
                </div>
            </div>
        )
    }
}
DetailPage = connect(mapStateToProps)(DetailPage) || DetailPage;
export default DetailPage