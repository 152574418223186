import React from 'react'
import classNames from 'classnames';
import { connect } from 'react-redux';
import api from '../../utils/api'
import Fetch from '../../utils/fetch-config'
import { List, Comment, Popconfirm } from 'antd'
import { Popover, Button, TextareaItem, Icon } from 'antd-mobile'
import { CaretDownOutlined, CaretUpOutlined, createFromIconfontCN, EllipsisOutlined } from '@ant-design/icons'
import { Grid, Toast } from 'antd-mobile-v5'
import { resetTimeType } from '../../hocs/getTime'
import dayjs from 'dayjs';
import AtLoading from '../../components/load-more';
import TitleBox from '../../components/title-box';
import CommentList from '../../components/comment-module/comment-list';
import ReleaseBox from '../../components/comment-module/release-box';
import CommentModule from '../../components/comment-module/index';

import './vote.scss'

import iconCUnlike from '../../assets/images/comment/comment-unlike.png';
import iconCLike from '../../assets/images/comment/comment-like.png';
import iconCReply from '../../assets/images/comment/comment-reply.png';

const Item = Popover.Item;

const mapStateToProps = store => ({
    userInfo: store.userInfo
});

class Vote extends React.Component {
    state={
        VoteStatus: 0,
        voteData: {},
        commentCount: 0,
        sortVisible: false,
        selectedId: 1,
        PageIndex: 1,
        childPageIndex: 1,
        sortSelected: '按时间正序',
        commentDataList: [],
        hasVote: false,
        commentValue: null,
        hasError: false,
        isFocus: false,
        parentCommentId: -1
    }
    componentWillMount() {
        let voteData = this.props.history.location.state;
        // this.getVoteMainInfoRecord();
        console.log(voteData)
        this.setState({
            voteData: voteData || {}
        },()=>{
            this.getCommentInsideListPage(2);
        })
    }
    componentWillReceiveProps () {}
    componentWillUnmount () { }
    componentDidShow () { }
    componentDidHide () { }

    async getVoteMainInfoRecord(){
        const { VoteStatus } = this.state;
        await Fetch.get(api.getVoteMainInfoRecord + `?MemberId=${this.props.userInfo?.Id}&VoteStatus=${VoteStatus}`).then(res=>{
            this.setState({
                voteData: res.data ?? {},
                // parentCommentId: res.data?.Id ?? -1
            },()=>{
                this.getCommentInsideListPage(2);
            })
        })
    }

    onReleaseComment = (value) => {
        this.setState({
            commentValue: value,
        });
    }

    async getCommentInsideListPage(status){
        const { voteData, selectedId, PageIndex } = this.state;
        let obj = {
            MemberId: this.props.userInfo.Id,
            PublishId: voteData?.Id,
            PageIndex: PageIndex,
            CommentStatus: status,
            SortNum: selectedId,
        }
        // console.log(obj)
        // return;
        await Fetch.post(api.getCommentInsideListPage, obj).then(res => {
            this.setState({
                CommentListData: res.data ? res.data?.List : [],
                commentCount: res.data ? res.data?.TotalCount : 0,
                PageIndex: res.data?.PageIndex
            },()=>{
                this.arrangeCommentList()
            })
        })
    }

    getCommentInsideParentIdListPage(item, event){
        const { childPageIndex } = this.state;
        console.log(item)
        Fetch.post(api.getCommentInsideParentIdListPage, {
            CommentParentId: item.Id,
            PageIndex: childPageIndex,
            CommentStatus: 2,
        }).then(res=>{
            res.data?.List.map(listitem=>{
                let div = document.createElement('div');
                div.classList.add('childlist');
                div.innerHTML=`<span class="username">${listitem.NickName}：</span><span class='item-comment'>${listitem.CommentDesc}</span>`;
                event.target.parentNode.insertBefore(div, event.target);
                if(event.target.parentNode.childNodes.length-1 >= item.list[0].TotalCount){
                    event.target.style.display='none';
                }else{
                    this.setState({
                        childPageIndex: childPageIndex+1
                    })
                }
            })
        });
    }

    onErrorClick(){
        Toast.show({
            content: '评论不能为空',
            maskClickable: false,
        });
    }

    childReply(id){
        this.setState({
            isFocus: true,
            commentValue: null,
            parentCommentId: id
        },()=>{
            this.customFocusInst.focus();
        })
    }

    async createVoteMainInfoRecord(id){
        await Fetch.post(api.createVoteMainInfoRecord, {
            MemberId: this.props.userInfo.Id,
            VoteDetailId: id,
            VoteMainId: this.state.voteData.Id
        }).then(res=>{
            Toast.show({
                content: res.message,
                maskClickable: false,
            })
        })

    }

    

    renderVoteHead(){
        const { voteData } = this.state;
        return (
            <div className='vote-header'>
                <div className='title'>
                    {voteData?.VoteTitle ?? ''}
                </div>
                <div className='center'>
                    <div className='time'>时间: {dayjs(voteData?.BeginDate).format('YYYY-MM-DD HH:mm') + '到' + dayjs(voteData?.EndDate).format('YYYY-MM-DD HH:mm')}</div>
                    <div className='vote_count'>
                        {voteData?.VoteMainCount ?? 0}人已投票
                    </div>
                </div>
                {/* <div className='content'>
                    {voteData?.VoteContent ?? ''}
                </div> */}
            </div>
        )
    }

    renderVoteBox(){
        const { voteData, voteList } = this.state;
        return (
            <div className='new_message_list'>
                <TitleBox
                    className='recommend-box-title-box'
                    title={voteData?.VoteLable ?? ''}
                    isMoreText={false}
                />
                    <Grid className='vote-box' columns={2} gap={[15, 16]}>
                        {voteData?.list && voteData?.list.map((item,index)=>{
                            return (
                                <Grid.Item key={item.Id}>
                                    <div className='box'>
                                        <div className='box-top'>
                                            <img className='vote-img' src={item?.VoteDetailImage} />
                                        </div>
                                        <div className='box-bottom'>
                                            <div className='vote-name'>
                                                <p className='fir'>{item?.VoteDetailName}</p>
                                                {/* <p className='sec'>(22)</p> */}
                                            </div>
                                            <Button
                                                className={classNames('vote-btn', {'isDisabled': voteData?.VoteResult})}
                                                disabled={voteData?.VoteResult}
                                                onClick={()=>{this.createVoteMainInfoRecord(item.Id)}}
                                            >
                                                {
                                                    voteData?.recordList && voteData?.recordList?.[index]?.Id === item.Id ? '已投票' : '投票'
                                                }
                                            </Button>
                                        </div>
                                    </div>
                                </Grid.Item>
                            )
                        })}
                    </Grid>
            </div>
        )
    }

    arrangeCommentList(){
        const { CommentListData } = this.state;
        let childCount = 0;

        let commentDataList = CommentListData.length > 0 && CommentListData.map((item,index)=>{
            return {
                actions: [
                    <div className='reply-box' key={`reply-${item.Id}`}>
                        <div className='comment-time'>{resetTimeType(item.AddDate)}</div>
                        <div className='comment-reply'>
                            <div onClick={()=>this.halderLike(item.Id, 1, 2, item.Praise)}>
                                <img className='icon' src={!item.Praise ? iconCUnlike : iconCLike} />
                                点赞
                            </div>
                            <div onClick={()=>{this.childReply(item.Id)}}>
                                <img className='icon' src={iconCReply} />
                                回复
                            </div>
                        </div>
                    </div>
                ],
                author: [
                    <div className='list-top' key={`list-${item.Id}`}>
                        <p className='username'>{item.NickName}</p>
                        <Popconfirm
                            arrowPointAtCenter
                            placement="bottomRight"
                            title='删除后无法恢复，确定要删除该评论吗?'
                            onConfirm={()=>this.deleteCommentInsideById(item.Id)}
                            okText="确定"
                            cancelText="取消"
                        >
                            <Icon type="ellipsis" />
                        </Popconfirm>
                    </div>
                ],
                avatar: item.AvatarUrl,
                content: (
                    <div>
                        <p className='parentComment'>{item.CommentDesc}</p>
                        {item.list.length > 0 && (
                            <div className='childComment' key={`childlist-${item.Id}`}>
                                {item.list.length > 0 && item.list.map(listItem=>{
                                    childCount = listItem.TotalCount;
                                    return (
                                        <div className='childlist' key={listItem.Id}>
                                            <span className="username">{listItem.NickName}：</span>
                                            <span className='item-comment'>{listItem.CommentDesc}</span>
                                        </div>
                                    )
                                })}
                                {(item.list.length >= 2 && childCount-item.list.length > 0) ? (
                                    <div className='more' style={{padding: '5px 0 0 0'}} onClick={this.getCommentInsideParentIdListPage.bind(this,item)}>查看全部({childCount})条回复</div>
                                ):null}
                            </div>
                        )}
                    </div>
                ),
            }
        })
        this.setState({
            commentDataList
        })
    }

    renderUserComment(){
        const { commentCount, commentDataList, sortVisible, sortSelected } = this.state;
        
        return(
            <div className='user-comment'>
                <div className='comment-header'>
                    <p>大家评价({commentCount})条</p>
                    <div>
                        <Popover mask
                            overlayClassName="sorttest"
                            overlayStyle={{ color: 'currentColor' }}
                            visible={sortVisible}
                            overlay={[
                                (<Item key="1" value="1" data-seed="logId">按时间正序</Item>),
                                (<Item key="2" value="2"  style={{ whiteSpace: 'nowrap' }}>按时间倒序</Item>),
                                (<Item key="3" value="3" > <span style={{ marginRight: 5 }}>按点赞倒序</span></Item>),
                            ]}
                            align={{
                            overflow: { adjustY: 0, adjustX: 0 },
                            offset: [-10, 0],
                            }}
                            onVisibleChange={this.handleSortVisibleChange}
                            onSelect={this.onSortSelect}
                        >
                            <div style={{
                                height: '100%',
                                padding: '0 15px',
                                marginRight: '-15px',
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                {sortSelected}
                                {sortVisible ? (<CaretUpOutlined />) : (<CaretDownOutlined />)}
                            </div>
                        </Popover>
                    </div>
                </div>
                <div className='comment-box'>
                    {commentDataList.length > 0 ? (
                        <List
                            className="comment-list"
                            itemLayout="horizontal"
                            dataSource={commentDataList}
                            renderItem={(item, index) => (
                                <li>
                                    <Comment
                                        key={index}
                                        actions={item.actions}
                                        author={item.author}
                                        avatar={item.avatar}
                                        content={item.content}
                                    />
                                </li>
                            )}
                        />
                    ): (
                        <AtLoading status="noMore" noMoreText='暂无评论' />
                    )}
                    {(commentDataList.length < commentCount) ? (<div className='more' onClick={()=>{}}>查看全部({commentCount})条评论</div>) : null}
                </div>
            </div>
        )
    }

    renderReleaseBox(){
        const { commentValue, isFocus } = this.state;

        return(
            <div className='release-comment-box'>
                <div className='comment-box-right' onClick={e=>e.stopPropagation()}>
                    <TextareaItem
                        type="text"
                        autoHeight
                        placeholder='赶紧说些什么...'
                        onChange={this.onReleaseComment}
                        value={commentValue}
                        ref={el => this.customFocusInst = el}
                        onFocus={()=>{
                            this.setState({
                                isFocus: true
                            })
                        }}
                        onBlur={()=>{
                            if(!commentValue)this.setState({isFocus: false, parentCommentId: -1});
                        }}
                        error={this.state.hasError}
                        onErrorClick={this.onErrorClick}
                    />
                    <Button className={classNames('sendBtn', {'isShow': isFocus})} style={{lineHeight: '3'}} onClick={()=>{this.sendCommon();this.setState({isFocus: false})}}>发送</Button>
                </div>
            </div>
        )
    }

    async onLike(objItem, status, type, opType, fileId){
        
        await Fetch.post(api.createCommentLikes,{
            ArticleId: objItem?.Id, // 文章id
            MemberId: this.props.userInfo.Id,
            Status: status, // 1点赞 2收藏 3查看
            Type: type, // 1文章 2评论 3其他
            OperationType: !opType ? 1 : 2, // 1新增 2修改
            FileCollectionId: fileId ?? 0   // 收藏文件夹id
        })
    }

    async onReply( objItem, commentValue, status, parentCommentId = -1, parentCommentMainId = 0){
        
        await Fetch.post(api.createCommentPublish,{
            MemberId: this.props.userInfo.Id, // 用户id
            CommentDesc: commentValue, // 内容
            ArticleId: objItem.Id, // 文章id
            Type: parentCommentId === -1 ? 1 : 2, // 1:1级评论 2:2级评论
            ParentCommentId: parentCommentId, //父级id
            CommentStatus: status, // 1知识库 2投票
            ParentCommentMainId: parentCommentMainId  // 1级评论id
        }).then((res)=>{
            Toast.show({
                content: res.message,
                maskClickable: false,
            });
        })
    }

    async deleteCommentInsideById(id) {
        return await new Promise(resolve => {
            Fetch.post(api.deleteCommentInsideById, {
                MemberId: this.props.userInfo.Id,
                CommentId: id
            }).then((res) => {
                Toast.show({
                    content: res.message,
                    maskClickable: false,
                });
                this.getCommentInsideListPage(2);
                setTimeout(()=>{
                    resolve()
                },0)
            });
        });
    }
    
    render() {
        const { CommentListData, voteData } = this.state;
        return (
            <div>
                <div style={{background: '#fff'}}>
                    {this.renderVoteHead()}
                    {this.renderVoteBox()}
                </div>
                {/* {this.renderUserComment()}
                {this.renderReleaseBox()} */}
                {/* <TitleBox /> */}

                {/* <CommentList commentArry={CommentListData} callBack={this.getCommentInsideListPage.bind(this)}/>
                <ReleaseBox type={2} itemData={voteData} onReply={this.onReply.bind(this)} callBack={this.getCommentInsideListPage.bind(this)}/> */}

                <CommentModule
                    objItem={voteData}
                    commentArry={CommentListData}
                    type={2}
                    onLike={this.onLike.bind(this)}
                    onReply={this.onReply.bind(this)}
                    callBack={this.getCommentInsideListPage.bind(this)}
                    deleteComment={this.deleteCommentInsideById.bind(this)}
                />
            </div>
        )
    }
}

Vote = connect(mapStateToProps)(Vote) || Vote;
export default Vote