import React from 'react'
import dayjs from 'dayjs';
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Grid, Input, Image } from 'antd-mobile-v5'

import styles from './index.module.scss'

import likeIcon from '../../assets/images/comment/comment-like.png';
import unLikeIcon from '../../assets/images/comment/comment-unlike.png';
import collectIcon from '../../assets/images/comment/alreadyCollected.png';
import unCollectIcon from '../../assets/images/comment/collection.png';
import replyIcon from '../../assets/images/comment/comment-reply.png';


class ReleaseBox extends React.Component {
    state={
        commentCount: 0,
        isLikeOn: this.props.isLikeOn ?? false,
        isCollectOn: this.props.isLikeOn ?? false,
    }

    onLike(){
        const { onLike } = this.props;
        onLike && onLike();
    }
    onCollect(){
        const { onLike } = this.props;
        onLike && onLike();
    }
    
    
    render() {
        const { type, openCommentPopup, closeCommentPopup, onLike, onCollect, callBack } = this.props;
        const { commentCount, isLikeOn, isCollectOn } = this.state;

        return (
            <div className={styles['release-box']}>
                <div style={{padding: '0 13px', textAlign: 'center'}}>
                    <Grid columns={type === 1 ? 3 : (type === 3 ? 2: 1)} gap={16}>
                        {(type === 1 || type === 3) && (<Grid.Item>
                            <div>
                                <Image src={ isLikeOn ? likeIcon : unLikeIcon } width={18.5} height={17} />
                                <p className={classNames('count-box',{'isLikeOn-count': isLikeOn})}>1-1</p>
                            </div>
                        </Grid.Item>)}
                        <Grid.Item>
                            <div onClick={()=>openCommentPopup && openCommentPopup()}>
                                <Image src={ replyIcon } style={{marginTop: 1}} width={19.5} height={16} />
                                <p className='count-box'>{type === 2 ? '去评论' : commentCount}</p>
                            </div>
                        </Grid.Item>
                        {type === 1 && (<Grid.Item>
                            <div>
                                <Image src={ isCollectOn ? collectIcon : unCollectIcon } width={17} height={17} />
                                <p className={classNames('count-box',{'isCollectOn-count': isCollectOn})}>3-3</p>
                            </div>
                        </Grid.Item>)}
                    </Grid>
                </div>
                {(type === 1 || type === 3) && (<div className='release-input-box' onClick={()=>openCommentPopup && openCommentPopup()}>
                    <Input className='release-input' placeholder='说点什么吧' />
                </div>)}

                
            </div>
        );
    }
}

ReleaseBox.defaultProps = {
    itemData: {},
    type: 1
};
ReleaseBox.propTypes = {
    itemData: PropTypes.object,
    type: PropTypes.number,
    onLike: PropTypes.func,
    onCollect: PropTypes.func,
    onReply: PropTypes.func,
};

export default ReleaseBox
