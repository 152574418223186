import React from 'react';
import NavTab from '../../components/navTab'
import 'moment/locale/zh-cn';
import api from '../../utils/api'
import Fetch from '../../utils/fetch-config'
// import { isEmptyObject, getAllUrlParam } from '../../utils/common'
import { Toast, Form, Input, Button, Radio, Space, TextArea, Picker } from 'antd-mobile-v5';
// import dayjs from 'dayjs';

import './interview.scss'

class Interview extends React.Component {
    formRef = React.createRef();

    state={
        questionList: [],
        cityVisible: false
    }

    componentWillMount() {
        // const params = getAllUrlParam(this.props.location.search.slice(1));
        // /pages/webviews/manage?router=/other/interview
        this.getQuestionList();
        this.getCityList();
    }
    componentWillReceiveProps () {}
    componentWillUnmount () { }
    componentDidShow () {}
    componentDidHide () { }

    async getQuestionList(){
        await Fetch.get(api.getQuestionList+'?Type=3').then(res=>{
            this.setState({
                questionList: res.data
            })
        })
    }
    async getCityList(){
        await Fetch.get(api.getCityList).then(res=>{
            this.setState({
                cityList: (res.data && res.data.length > 0) ? res.data : []
            })
        })
    }


    submitForm(formValue){
        const detail = formValue['Detail'];
        let Detail = [];
        if(detail){
            for (let key in detail){
                let itemObj = {};
                itemObj.QuestionId = key;
                itemObj.ContentName = detail[key];
                Detail.push(itemObj)
            }
        }
        const values = {
            ...formValue,
            CityName: formValue['CityName'][0],
            Detail
        }
        console.log(values)

        Fetch.post(api.createUserInterview, values).then(res=>{
            Toast.show({
                content: res.message,
                maskClickable: false,
                afterClose: ()=>{
                    window.wx.miniProgram.switchTab({
                        url: `/pages/index/index`
                    });
                }
            })
        })
    }

    renderForm(){
        const { questionList, cityList } = this.state;
        console.log(cityList)
        return(
            <Form
                className='interviewForm'
                ref={this.formRef}
                footer={
                    <Button className='submit-btn' block type='submit' color='primary'>
                      提交
                    </Button>
                }
                onFinish={(values)=>this.submitForm(values)}
            >
                {cityList?.length > 0 && (<Form.Item
                    className='area'
                    name='CityName'
                    label='区域'
                    trigger='onConfirm'
                    onClick={() => {
                        this.setState({
                            cityVisible: true
                        })
                    }}
                    rules={[{ required: true, message: '请选择区域' }]}
                >
                    <Picker
                        columns={[cityList]}
                        visible={this.state.cityVisible}
                        onClose={() => {
                            this.setState({
                                cityVisible: false
                            })
                        }}
                        onClick={e => {e.stopPropagation()}}
                    >
                        {items => {
                            if (items.every(item => item === null)) {
                                return '未选择'
                            } else {
                                return items.map(item => item?.label ?? '未选择')
                            }
                        }}
                    </Picker>
                </Form.Item>)}
                <Space className='box'>
                    <Form.Item label='班主任' name='TeacherName' style={{paddingLeft: '0px'}} rules={[{ required: true, message: '班主任姓名不能为空' }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item label='姓名' name='RealName' rules={[{ required: true, message: '姓名不能为空' }]}>
                        <Input />
                    </Form.Item>
                </Space>
                <Space className='box'>
                    <Form.Item label='手机号' name='Mobile' style={{paddingLeft: '0px'}} rules={[{ required: true, message: '手机号不能为空' }]}>
                        <Input maxLength={11}/>
                    </Form.Item>
                    <Form.Item label='性别' name='Sex' rules={[{ required: true, message: '请选择性别' }]}>
                        <Radio.Group>
                            <Space>
                                <Radio value={1}>男</Radio>
                                <Radio value={2}>女</Radio>
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </Space>
                <Form.Item name='Detail'>
                    {questionList && questionList.map(item=>{
                        return (
                            <Form.Item key={item.Id} label={`${item.QuestionName}`} name={['Detail', `${item.Id}`]} rules={[{ required: true, message: '采访问题不能为空' }]}>
                                <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                            </Form.Item>
                        )
                    })}
                </Form.Item>
            </Form>
        )
    }
    

    render() {
        return (
            <div>
                {process.env.NODE_ENV === 'production' ? null : <NavTab title="采访登记表" onClick={()=>{this.props.history.goBack();}}/>}
                {this.renderForm()}
            </div>
        )
    }
}

export default Interview