import React from 'react'
import { NavBar, Icon } from 'antd-mobile'
import PropTypes from 'prop-types'

class NavTab extends React.Component {
    render() {
        const {
            title,
            more,
            hasSearchIcon,
            hasRightIcon,
            onClick
        } = this.props;

        return (
            <NavBar
                mode={more}
                icon={<Icon type="left" color="#999999"/>}
                onLeftClick={()=>{
                    onClick && onClick()
                }}
                rightContent={[
                    (hasSearchIcon && <Icon key="0" type="search" style={{ marginRight: '16px' }} />),
                    (hasRightIcon && <Icon key="1" type="ellipsis" />),
                ]}
            >{title}</NavBar>
        );
    }
}

NavTab.defaultProps = {
    title: '耳道',
    more: 'light',
    hasSearchIcon: false,
    hasRightIcon: false,
};
NavTab.propTypes = {
    title: PropTypes.string.isRequired,
    more: PropTypes.string,
    hasSearchIcon: PropTypes.bool,
    hasRightIcon: PropTypes.bool,
    onClick: PropTypes.func
};

export default NavTab

