const resetTimeType = (time, type) =>{
    //type: YY-mm-dd HH:mm  YY-mm-dd  HH:mm
    let timeStr = new Date(time).toJSON();
    let newTimeStr = new Date(+new Date(timeStr) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '').replace(/-/g, '/');
    let newTimeStamp = new Date(newTimeStr).getTime();
    let year = new Date(newTimeStr).getFullYear(); //获取年份
    let month = new Date(newTimeStr).getMonth() + 1; // 获取月份
    let day = new Date(newTimeStr).getDate(); // 获取具体日
    // let minutes=15*60*1000;
    let newTime = new Date(newTimeStamp).toLocaleTimeString('chinese',{hour12:false}).substr(0,5);// 获取小时和分钟

    let backData = '';
    switch (type) {
        case 'YY-mm-dd HH:mm':
            backData = year+'-'+month+'-'+day+' '+' '+newTime;
            break;
        case 'YY-mm-dd':
            backData = year+'-'+month+'-'+day;
            break;
        case 'HH:mm':
            backData = newTime;
            break;
        default:
            backData = month+'-'+day+' '+newTime;
            break;
    }

    return backData;
}

export {resetTimeType}