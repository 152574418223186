import React from 'react'
import { connect } from 'react-redux';
import api from '../../utils/api'
import Fetch from '../../utils/fetch-config'
import { List } from 'antd-mobile-v5'
import dayjs from 'dayjs';

const mapStateToProps = store => ({
    userInfo: store.userInfo
});


class VoteList extends React.Component {
    state={
        VoteStatus: 0,
        voteList: []
    }
    componentWillMount() {
        this.getVoteMainInfoRecord();
    }
    componentWillReceiveProps () {}
    componentWillUnmount () { }
    componentDidShow () { }
    componentDidHide () { }

    getVoteMainInfoRecord(){
        const { VoteStatus } = this.state;
        Fetch.get(api.getVoteMainInfoRecord + `?MemberId=${this.props.userInfo?.Id}&VoteStatus=${VoteStatus}`).then(res=>{
            this.setState({
                voteList: res?.data ?? []
            })
        })
    }
    
    render() {
        const { voteList } = this.state;
        return (
            <List>
                {voteList.map(item=>{
                    return(
                        <List.Item key={item.Id} onClick={()=>{this.props.history.push({ pathname: `/vote/${item?.Id}`, state: item})}}>{item?.VoteTitle}</List.Item>
                    )
                })}
            </List>
        )
    }
}

VoteList = connect(mapStateToProps)(VoteList) || VoteList;
export default VoteList