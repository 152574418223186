import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import {withRouter} from "react-router-dom";

import './index.scss'
import { Toast } from 'antd-mobile';

class TitleBox extends React.Component {
    render() {
        const {
            title,
            more,
            isMoreText,
            moreText,
            colorString,
            isIcon,
            onClick
        } = this.props;
        
        return (
            <div className={classNames('title-box', this.props.className)}>
                <div className="title-box__left">
                    <div className="decorate-line"></div>
                    <p className="text">{title}</p>
                </div>
                {this.props.children}
                {
                    more &&
                    (
                        <div
                            className="title-box__right"
                            onClick={
                                (e) => {
                                    if (this.props.url !== '#'){
                                        this.props.history.push(this.props.url)
                                    }else{
                                        onClick ? onClick(e) : Toast.info('待开发');
                                    };
                                }
                            }>
                            {isMoreText && (<p className="text">{moreText}</p>)}
                            {isIcon && (<div className={`arrow-right arrow-right--${colorString}`}></div>)}
                        </div>
                    )
                }
            </div>
        );
    }
}

const lastDefaultProps = TitleBox.defaultProps;
const lastPropTypes = TitleBox.propTypes;
TitleBox.defaultProps = Object.assign({}, lastDefaultProps, {
    more: true,
    isIcon: true,
    isMoreText: true,
    moreText: '更多',
    colorString: 'dark'
});
TitleBox.propTypes = Object.assign({}, lastPropTypes, {
    title: PropTypes.string.isRequired,
    more: PropTypes.bool,
    isIcon: PropTypes.bool,
    isMoreText: PropTypes.bool,
    moreText: PropTypes.string,
    colorString: PropTypes.string,
    onClick: PropTypes.func
});

export default withRouter(TitleBox)
