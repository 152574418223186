export const formatTime = (date: any): string => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const second = date.getSeconds();

    return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':');
};
export const formatNumber = (n: number | string): string => {
    n = n.toString();
    return n[1] ? n : '0' + n;
};
export const getTimeObject = (dateString: string) => {
    let d = new Date(dateString);
    return {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate(),
      hour: d.getHours(),
      minute: d.getMinutes(),
      second: d.getSeconds()
    };
};
export const isObject = function(arg: any) {
    return arg != null && typeof arg === 'object' && !Array.isArray(arg);
};
export const isEmptyObject = function (arg: any): boolean {
    if (!isObject(arg)) {
        return false;
    }
    return Object.keys(arg).length === 0;
};
export const copyData = function (data: any) {
    return JSON.parse(JSON.stringify(data));
};
export const getAllUrlParam = (str: string) => {
    // debugger;
    let urlArr = str.split('&');
    let obj: any = {};
    for(let i = 0; i < urlArr.length; i++) {
        let arg = urlArr[i].split('=');
        obj[arg[0]] = arg[1];
    }
    return obj;
};
export const getVideoPosterUrl = (url: string) => {
    return new Promise(resolve => {
        let dataURL = '';
        let video = document.createElement('video');
        video.setAttribute('crossOrigin', 'anonymous');
        video.setAttribute('src', url);
        video.currentTime = 1;
        video.addEventListener('loadeddata', function() {
            let canvas = document.createElement('canvas');
            let width = this.videoWidth,
                height = this.videoHeight;

            canvas.width = width;
            canvas.height = height;
            // @ts-ignore
            canvas.getContext('2d').drawImage(video, 0, 0, width, height);
            dataURL = canvas.toDataURL('image/jpeg');
            resolve(dataURL);
        });
    })
};
const countDecimals = (num: any) => {
    var len = 0;
    try {
        num = Number(num);
        var str = num.toString().toUpperCase();
        if (str.split('E').length === 2) { // scientific notation
            var isDecimal = false;
            if (str.split('.').length === 2) {
                str = str.split('.')[1];
                if (parseInt(str.split('E')[0]) !== 0) {
                    isDecimal = true;
                }
            }
            let x = str.split('E');
            if (isDecimal) {
                len = x[0].length;
            }
            len -= parseInt(x[1]);
        } else if (str.split('.').length === 2) { // decimal
            if (parseInt(str.split('.')[1]) !== 0) {
                len = str.split('.')[1].length;
            }
        }
    } catch(e) {
        throw e;
    } finally {
        if (isNaN(len) || len < 0) {
            len = 0;
        }
        return len;
    }
};
const convertToInt = (num: any) => {
    num = Number(num);
    var newNum = num;
    var times = countDecimals(num);
    var temp_num = num.toString().toUpperCase();
    if (temp_num.split('E').length === 2) {
        newNum = Math.round(num * Math.pow(10, times));
    } else {
        newNum = Number(temp_num.replace(".", ""));
    }
    return newNum;
};
const getCorrectResult = (type: any, num1: any, num2: any, result: any) => {
    var temp_result = 0;
    switch (type) {
        case "add":
            temp_result = num1 + num2;
            break;
        case "sub":
            temp_result = num1 - num2;
            break;
        case "div":
            temp_result = num1 / num2;
            break;
        case "mul":
            temp_result = num1 * num2;
            break;
    }
    if (Math.abs(result - temp_result) > 1) {
        return temp_result;
    }
    return result;
};
export const accAdd = (arg1: any, arg2: any) => {
    var r1, r2, m, c;
    try {
        r1 = arg1.toString().split(".")[1].length;
    }
    catch (e) {
        r1 = 0;
    }
    try {
        r2 = arg2.toString().split(".")[1].length;
    }
    catch (e) {
        r2 = 0;
    }
    c = Math.abs(r1 - r2);
    m = Math.pow(10, Math.max(r1, r2));
    if (c > 0) {
        var cm = Math.pow(10, c);
        if (r1 > r2) {
            arg1 = Number(arg1.toString().replace(".", ""));
            arg2 = Number(arg2.toString().replace(".", "")) * cm;
        } else {
            arg1 = Number(arg1.toString().replace(".", "")) * cm;
            arg2 = Number(arg2.toString().replace(".", ""));
        }
    } else {
        arg1 = Number(arg1.toString().replace(".", ""));
        arg2 = Number(arg2.toString().replace(".", ""));
    }
    return (arg1 + arg2) / m;
};
export const accSub = (arg1: any, arg2: any) => {
    var r1, r2, m, n;
    try {
        r1 = arg1.toString().split(".")[1].length;
    }
    catch (e) {
        r1 = 0;
    }
    try {
        r2 = arg2.toString().split(".")[1].length;
    }
    catch (e) {
        r2 = 0;
    }
    m = Math.pow(10, Math.max(r1, r2)); //last modify by deeka //动态控制精度长度
    n = (r1 >= r2) ? r1 : r2;
    return ((arg1 * m - arg2 * m) / m).toFixed(n);
};
export const accMul = (arg1: any, arg2: any) => {
    var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
    try {
        m += s1.split(".")[1].length;
    }
    catch (e) {
    }
    try {
        m += s2.split(".")[1].length;
    }
    catch (e) {
    }
    return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
};
export const accDiv = (arg1: any, arg2: any) => {
    arg1 = Number(arg1);
    arg2 = Number(arg2);
    var t1 = 0, t2 = 0, dec1, dec2;
    try { t1 = countDecimals(arg1); } catch (e) { }
    try { t2 = countDecimals(arg2); } catch (e) { }
    dec1 = convertToInt(arg1);
    dec2 = convertToInt(arg2);
    var result = accMul((dec1 / dec2), Math.pow(10, t2 - t1));
    return getCorrectResult("div", arg1, arg2, result);
};
// 购物车里是否有已选的相同的商品，且相同的规格
export const hasItem = (target: any, item: any) => {
    let targetItemGroups = target.filter((element: any) => element.Id === item.Id);
    if (!targetItemGroups.length) return false;

    let resultArray: Array<any> = [];
    for (let k = 0; k < targetItemGroups.length; k++) {
        let targetItem = targetItemGroups[k];
        let count = 0;
        for (let i = 0; i < item.optionsTag.length; i++) {
            let option = item.optionsTag[i];
            for (let j = 0; j < targetItem.optionsTag.length; j++) {
                let targetOption = targetItem.optionsTag[j];
                if (option.GoodsSpecDetailId === targetOption.GoodsSpecDetailId) {
                    count++;
                }
            }
        }
        resultArray.push(count);
    }

    return Math.max.apply(null, resultArray) === item.optionsTag.length;
};
// 获取购物车里已选的相同的商品，且相同的规格的索引
export const getItemIndex = (target: any, item: any) => {
    let resultArray: Array<any> = [];
    for (let k = 0; k < target.length; k++) {
        let targetItem = target[k];
        if (targetItem.Id !== item.Id) {
            continue;
        }
        let count = 0;
        for (let i = 0; i < item.optionsTag.length; i++) {
            let option = item.optionsTag[i];
            for (let j = 0; j < targetItem.optionsTag.length; j++) {
                let targetOption = targetItem.optionsTag[j];
                if (option.GoodsSpecDetailId === targetOption.GoodsSpecDetailId) {
                    count++;
                }
            }
        }
        resultArray.push({
            index: k,
            count
        });
    }
    let counts = resultArray.map(el => el.count);
    let _index = resultArray.findIndex(element => element.count === Math.max.apply(null, counts));
    return resultArray[_index].index;
};
export const getString = (arg: any) => {
    return arg || '';
};
export const mergeObjects = function(obj1: any, obj2: any) {
    const result = Object.assign({}, obj1);
    if (isObject(obj1) && isObject(obj2)) {
        for (const p in obj2) {
            if (isObject(obj1[p]) && isObject(obj2[p])) {
                result[p] = mergeObjects(obj1[p], obj2[p]);
            } else {
                result[p] = obj2[p];
            }
        }
    }
    return result;
};
export const picScale = (array: any) => {
    let len = array.length;
    return len === 1 ? 3 : len === 2 ? 2 : 1;
};
export const hasSameProps = (other: any, key: string) => (item: any) => item[key] === other[key];
/**
 * 获取 源 瀑布流商品列表，并进行拷贝，格式化输出
 * @param source 源 瀑布流商品列表
 */
export const getSourceArray = (source: Array<any>) => {
    let res: Array<any> = [];
    for (let i = 0; i < source.length; i++) {
        res = [...source[i]];
    }
    return res;
};
/**
 * 获取 源 瀑布流商品列表 内的字段名
 * @param source 源 瀑布流商品列表
 * @param item 更改过的列表项
 * @param key item下的字段
 */
export const getSourceArrayField = (source: Array<any>, item: any, key: string) => {
    let sourceArray = getSourceArray(source);
    let index = sourceArray.findIndex(hasSameProps(item, 'Id'));
    return sourceArray[index][key];
};